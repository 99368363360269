import React, { useEffect } from 'react';
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

const TrackPageView = () => {
  const theme = useTheme();
  const location = useLocation();

  // Initialize Google Analytics 4 (GA4) and Facebook Pixel
  useEffect(() => {
    // GA4 initialization
    ReactGA.initialize('G-V3L3YWDEQQ');

    // Facebook Pixel initialization
    ReactPixel.init('1981669865607256');  // Replace with your actual Pixel ID

    // Track page view when the location (URL) changes
    ReactGA.set({ page: location.pathname });
    ReactGA.send('pageview');

    // Track Facebook page view on route change
    ReactPixel.pageView();

  }, [location]);

  return null; // This component doesn't render anything
};

export default TrackPageView;