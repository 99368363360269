import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Navbar from "../../components/Navbar";

export default function AboutHeader() {
  const theme = useTheme();

  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  const headerimg = require("../../assets/abouthero.png");
  const mobileHeaderImg = require("../../assets/MobileAboutHeaderBg.png");
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: phone ? "25rem" : tablet ? "30rem" : "38rem",
          backgroundImage: `url(${phone ? mobileHeaderImg : headerimg})`,
          backgroundPosition: "center top",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        <Navbar color="black" />
        <Box
          sx={{
            width: phone ? "90%" : "100%",
            minHeight: phone || tablet ? "fit-content" : "20rem",
            margin: tablet ? "4rem auto 0 auto" : "0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: phone ? "space-evenly" : "center",
            // border: "1px solid red",
          }}
        >
          <h1
            style={{
              marginBottom: phone ? "1rem" : "1rem",
              marginTop: phone ? "2rem" : 0,
              fontSize: phone ? "1.75rem" : tablet ? "2.5rem" : "4rem",
            }}
          >
            We're here with <i style={{ color: "#996076" }}>you</i>
          </h1>
          <p
            style={{
              maxWidth: phone ? "90%" : tablet ? "75%" : "50%",
              fontSize: "1rem",
              lineHeight: 1.5,
            }}
          >
            For us, aging isn’t a business angle. It’s real life. It’s our
            parents, our friends and neighbors—and yours. That’s why we started
            building our platform for flexible senior care back in 2018.
          </p>
        </Box>
      </Box>
      {/* ----------- Subheader ----------- */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
          height: "10rem",
        }}
      >
        <h1 style={{ fontSize: phone ? "1.75rem" : "2.5rem" }}>Our approach</h1>
        <p
          style={{
            maxWidth: phone ? "90%" : "90%",
            fontSize: "1rem",
            lineHeight: 1.5,
            marginTop: "1rem",
          }}
        >
          We’re keeping things simple. Get quality in-home help that works for
          you.{" "}
        </p>
      </Box>
    </>
  );
}
