import * as React from "react";
import { Box, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

interface HelpYouNeedProps {}

const HelpYouNeed: React.FC<HelpYouNeedProps> = () => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  // const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  const table = require("../../assets/Events/CompressedWebP/Bullets.webp");
  const tableMbl = require("../../assets/Events/CompressedWebP/BulletsMobile.webp");
  const tableTab = require("../../assets/Events/CompressedWebP/BulletsTablet.webp");

  const curve = require("../../assets/helpyouneedbottomcurve.png");
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "fit-content",
          minHeight: phone ? "85vh" : "30rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: phone ? "center" : "space-evenly",
          alignItems: "center",
          margin: phone ? "0 auto" : " 0 auto",
          backgroundColor: "#FAF8F5",
          padding: phone ? "2rem 1rem" : "0 ",
          maxWidth: "100vw",
        }}
      >
        <h1
          style={{
            fontSize: phone ? "1.75rem" : tablet ? "2rem" : "2.5rem",
            marginBottom: phone ? "1rem" : "0",
            marginTop: phone ? "1rem" : "5rem",
          }}
        >
          The help you need,{" "}
          <i style={{ color: "#795EC6" }}>all in one place</i>
        </h1>
        <Paper
          sx={{
            padding: phone ? "1.5rem" : "0",
            borderRadius: phone ? "2rem" : "3rem",
            width: "90%",
            margin: tablet ? "3rem auto" : "5rem auto",
          }}
        >
          <img
            src={phone ? tableMbl : tablet ? tableTab : table}
            alt=""
            style={{
              width: "90%",
              margin: "2rem auto",
              maxWidth: "max-content",
            }}
          />
        </Paper>
      </Box>
      <img src={curve} alt="" />
    </>
  );
};

export default HelpYouNeed;
