import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowButton from "../../components/ArrowButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

interface PhotoLeftColumnProps {
  img: string;
  mobileImg: string;
  title1: string;
  title2: string;
  bullets: string[];
  cta: string;
  // color of second line of the header
  subColor: string;
  link: string;
}

const PhotoLeftColumn: React.FC<PhotoLeftColumnProps> = ({
  img,
  title1,
  title2,
  bullets,
  cta,
  subColor,
  mobileImg,
  link,
}) => {
  const [dense, setDense] = React.useState(false);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box
      sx={{
        width: phone ? "100%" : tablet ? "90%" : "85%",
        height: phone || tablet ? "fit-content" : "38rem",
        minHeight: "fit-content",
        display: "flex",
        flexDirection: phone ? "column" : "row",
        justifyContent: "center",
        margin: phone ? "0 auto" : "3rem auto",
        paddingBottom: phone ? "2rem" : 0,
      }}
    >
      {/* image */}

      <Box
        sx={{
          height: phone ? "100%" : tablet ? "25rem" : "35rem",
          borderRadius: phone ? "2.5rem" : "3rem",
          aspectRatio: "1",
          backgroundImage: `url(${phone ? mobileImg : img})`,
          backgroundPosition: phone ? "center top" : "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: phone ? "cover" : "cover",
          margin: phone ? "1rem" : 0,
        }}
      ></Box>

      {/* text */}

      <Box
        sx={{
          width: phone ? "100%" : tablet ? "100%" : "50%",
          maxWidth: phone || tablet ? "100%" : "max-content",

          padding: phone || tablet ? "0 1.5rem" : "5rem",
          textAlign: "left",
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h1
            style={{
              fontSize: phone || tablet ? "1.75rem" : "2.5rem",
              margin: 0,
              padding: 0,
              marginLeft: phone ? "1rem" : 0,
            }}
          >
            {title1}
            <br />
            <span
              style={{
                fontStyle: "italic",
                color: subColor,
                fontSize: phone || tablet ? "1.75rem" : "2.5rem",
              }}
            >
              {title2}
            </span>
          </h1>
        </div>

        <Grid item xs={12} md={12} lg={6}>
          <Demo>
            <List dense={dense}>
              {bullets.map((bullet) => {
                return (
                  <ListItem
                    sx={{ padding: tablet ? ".25rem 0" : ".5rem 1rem" }}
                  >
                    <ListItemIcon>
                      <CheckCircleIcon sx={{ color: "#77C2D8" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={bullet}
                      primaryTypographyProps={{
                        fontFamily: "Sitebody !important",
                        fontSize: tablet ? "1rem" : "1.1rem",
                        width: "100%",
                      }}
                      // sx={{ fontFamily: "Sitebody !important" }}
                      // secondary={secondary ? "Secondary text" : null}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Demo>
        </Grid>

        <Link to={link}>
          <Button
            variant="contained"
            endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
            sx={{
              minWidth: phone ? "fit-content" : "40%",
              width: phone ? "100%" : "fit-content",
              height: tablet ? "3rem" : "3.5rem",
              marginTop: tablet ? "1rem" : "1.5rem",
              fontSize: "18px",
              "&:hover": {
                background: "#FECA5B",
                // Add other hover styles as needed
              },
            }}
          >
            {cta}
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default PhotoLeftColumn;
