import * as React from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

import PeopleIcon from "../../assets/icons/PeopleIcon.tsx";
import TimeIcon from "../../assets/icons/TimeIcon.tsx";
import SubscriptionIcon from "../../assets/icons/SubscriptionIcon.tsx";
import CheckIcon from "../../assets/icons/CheckIcon.tsx";
import Divider from "@mui/material/Divider";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: ".25rem",
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "flex",
  flexDirection: "row",
  width: "20%",
  height: "5rem",
  justifyContent: "space-evenly",
  alignItems: "center",
}));

export default function NewIconRepeater() {
  const theme = useTheme();

  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  const teal = require("../../assets/icons/CheckIcon.tsx");
  const bgimage = require("../../assets/Home/CompressedWebP/MobileIconRepeater.webp");
  return (
    <>
      {/* phone */}
      <div
        style={{
          display: phone ? "flex" : "none",
          position: "relative",
          left: 0,
          right: 0,
          backgroundImage: `url(${bgimage})`,
          margin: "0 auto",
          width: "90vw",
          height: "40vh",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      ></div>

      {/* desktop */}
      <div
        style={{
          display: phone ? "none" : tablet ? "none" : "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          borderTop: "1px solid #EBE9E6",
          borderBottom: "1px solid #EBE9E6",
          backgroundColor: "#FFFFFF",
          width: "100%",
          minHeight: "7rem",
          [theme.breakpoints.down("sm")]: {
            display: "none !important",
          },
        }}
      >
        <Divider
          variant="fullWidth"
          sx={{ color: "black", border: "black", zIndex: "1000" }}
        />
        <hr />
        <Stack
          direction="row"
          spacing={0}
          sx={{
            width: "85%",

            justifyContent: "space-evenly",
            margin: "0 auto",
          }}
        >
          <Item
            sx={{ backgroundColor: "#FFFFFF", height: "6rem" }}
            elevation={0}
          >
            <PeopleIcon />
            <p style={{ width: "70%", textAlign: "left", fontSize: "1.1rem" }}>
              Choose your own Helper
            </p>
          </Item>
          <Item
            sx={{ backgroundColor: "#FFFFFF", height: "6rem" }}
            elevation={0}
          >
            <CheckIcon />
            <p style={{ width: "70%", textAlign: "left", fontSize: "1.1rem" }}>
              100% Background Checked
            </p>
          </Item>
          <Item
            sx={{ backgroundColor: "#FFFFF", height: "6rem" }}
            elevation={0}
          >
            <SubscriptionIcon />
            <p style={{ width: "70%", textAlign: "left", fontSize: "1.1rem" }}>
              No commitments or subscriptions
            </p>
          </Item>
          <Item
            sx={{ backgroundColor: "#FFFFFF", height: "6rem" }}
            elevation={0}
          >
            <TimeIcon />
            <p style={{ width: "70%", textAlign: "left", fontSize: "1.1rem" }}>
              Live customer support
            </p>
          </Item>
        </Stack>
        <Divider variant="fullWidth" sx={{ opacity: "0.5" }} />
      </div>

      {/* tablet */}
      <div
        style={{
          display: phone ? "none" : tablet ? "flex" : "none",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          borderTop: "1px solid #EBE9E6",
          borderBottom: "1px solid #EBE9E6",
          backgroundColor: "#FFFFFF",
          width: "100%",
          minHeight: "11rem",
          paddingBottom: "2rem",
          [theme.breakpoints.down("sm")]: {
            display: "none !important",
          },
        }}
      >
        <Divider
          variant="fullWidth"
          sx={{ color: "black", border: "black", zIndex: "1000" }}
        />
        <hr />
        <Stack
          direction="row"
          spacing={0}
          sx={{
            width: "100%",
            justifyContent: "space-evenly",
            margin: "0 auto",
          }}
        >
          <Item
            sx={{
              backgroundColor: "#FFFFFF",
              height: "10rem",
              display: "flex",
              flexDirection: "column",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
            elevation={0}
          >
            <PeopleIcon />
            <p style={{ width: "70%", textAlign: "center", fontSize: "1rem" }}>
              1000s of Helpers Available
            </p>
          </Item>
          <Item
            sx={{
              backgroundColor: "#FFFFFF",
              height: "10rem",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
            elevation={0}
          >
            <CheckIcon />
            <p style={{ width: "70%", textAlign: "center", fontSize: "1rem" }}>
              100% Background Checked
            </p>
          </Item>
          <Item
            sx={{
              backgroundColor: "#FFFFFF",
              height: "10rem",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
            elevation={0}
          >
            <SubscriptionIcon />
            <p style={{ width: "70%", textAlign: "center", fontSize: "1rem" }}>
              No commitments or subscriptions
            </p>
          </Item>
          <Item
            sx={{
              backgroundColor: "#FFFFFF",
              height: "10rem",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
            elevation={0}
          >
            <TimeIcon />
            <p style={{ width: "70%", textAlign: "center", fontSize: "1rem" }}>
              One-time tasks or ongoing help
            </p>
          </Item>
        </Stack>
        <Divider variant="fullWidth" sx={{ opacity: "0.5" }} />
      </div>
    </>
  );
}
