import * as React from "react";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Navbar from "../../components/Navbar";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";

interface PartnerHeaderProps {
  partner: string;
  showLogo: boolean;
}

const PartnerHeader: React.FC<PartnerHeaderProps> = ({ partner, showLogo }) => {
  const theme = useTheme();
  const [utm, setUTM] = useState(
    "/?utm_campaign=facility_launch_24&utm_source=events&utm_medium=landing_page&utm_content=cta&utm_id=website"
  );
  const [logo, setLogo] = useState("");
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  // const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  const promo = require("../../assets/PromoBanner.png");
  const promoMbl = require("../../assets/PromoBannerMobile.png");
  const promoTablet = require("../../assets/EventsPromoBannerTablet.png");
  const headerimg = require("../../assets/partnerheaderdesktop.png");
  const mobileHeaderImg = require("../../assets/MobileAboutHeaderBg.png");
  const headerimgTablet = require("../../assets/eventsheadertablet.png");

  const ebgLogo = require("../../assets/ebg-logo.png");
  const benehealthLogo = require("../../assets/beneplace-logo.png");
  const benefithubLogo = require("../../assets/benefithub.png");

  useEffect(() => {
    if (partner === "ebg") {
      setLogo(ebgLogo);
      setUTM(
        "/?utm_campaign=benefits_launch_24&utm_source=ebg&utm_medium=landing_page&utm_content=cta&utm_id=employee_benefits"
      );
    }
    if (partner === "beneplace") {
      setLogo(benehealthLogo);
      setUTM(
        "/?utm_campaign=benefits_launch_24&utm_source=beneplace&utm_medium=landing_page&utm_content=cta&utm_id=employee_benefits"
      );
    }
    if (partner === "benefithub") {
      setLogo(benefithubLogo);
      setUTM(
        "/?utm_campaign=benefits_launch_24&utm_source=benefithub&utm_medium=landing_page&utm_content=cta&utm_id=employee_benefits"
      );
    }

    if (partner === "amac") {
      setUTM(
        "/?utm_campaign=benefits_launch_24&utm_source=AMAC&utm_medium=landing_page&utm_content=cta&utm_id=employee_benefits"
      );
    }

    if (partner === "gateway") {
      setUTM(
        "/?utm_campaign=benefits_launch_24&utm_source=rewardgateway&utm_medium=landing_page&utm_content=cta&utm_id=employee_benefits"
      );
    }

    if (partner === "vantage") {
      setUTM(
        "/?utm_campaign=benefits_launch_24&utm_source=vantagecircle&utm_medium=landing_page&utm_content=cta&utm_id=employee_benefits"
      );
    }
    if (partner === "bennie") {
      setUTM(
        "/?utm_campaign=benefits_launch_24&utm_source=bennie_health&utm_medium=landing_page&utm_content=cta&utm_id=employee_benefits"
      );
    }
    if (partner === "fond") {
      setUTM(
        "/?utm_campaign=benefits_launch_24&utm_source=fond&utm_medium=landing_page&utm_content=cta&utm_id=employee_benefits"
      );
    }
  }, [partner]);

  return (
    <>
      <Navbar color="white" />

      <Link to={`https://app.herewith.com/sign-up${utm}`}>
        <img
          src={promo}
          alt="promotional banner - get a free hour of service"
          loading="lazy"
          style={{ display: phone || tablet ? "none" : "block", width: "100%" }}
        />
      </Link>

      <Link to={`https://app.herewith.com/sign-up${utm}`}>
        <img
          src={promoTablet}
          alt="promotional banner - get a free hour of service"
          loading="lazy"
          style={{
            display: phone ? "none" : tablet ? "flex" : "none",
            width: "100%",
          }}
        />
      </Link>

      <Link to={`https://app.herewith.com/sign-up${utm}`}>
        <img
          src={promoMbl}
          alt="promotional banner - get a free hour of service"
          loading="lazy"
          style={{
            display: phone ? "block" : "none",
            borderBottom: "2px solid white",
            width: "100%",
          }}
        />
      </Link>

      <Box
        sx={{
          width: "100%",
          height: phone ? "42rem" : tablet ? "38rem" : "44rem",
          minHeight: phone || tablet ? "fit-content" : "100%",
          backgroundImage: `url(${phone ? mobileHeaderImg : headerimg})`,
          backgroundPosition: "center ",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Box
          sx={{
            width: phone ? "90%" : tablet ? "45%" : "38%",
            minHeight: phone || tablet ? "fit-content" : "100%",
            margin: phone ? "1rem" : tablet ? "1rem 0 0 3rem" : "0 0 0 5rem ",
            display: "flex",
            flexDirection: "column",
            alignItems: phone ? "center" : "space-between",
            justifyContent: phone ? "space-evenly" : "center",
            textAlign: phone ? "center" : "left",
            paddingBottom: tablet ? "5rem" : 0,
          }}
        >
          {showLogo && (
            <img
              src={logo}
              alt="logo"
              loading="lazy"
              style={{
                width: phone || tablet ? "65%" : "70%",
                marginBottom: phone ? "0" : "2rem",
                marginTop: phone || tablet ? "1.5rem" : 0,
              }}
            />
          )}

          <h1
            style={{
              marginBottom: phone ? "1rem" : "2rem",
              marginTop: phone ? "2rem" : 0,
              fontSize: phone ? "1.75rem" : tablet ? "2rem" : "2.5rem",
            }}
          >
            Peace of mind for you,{" "}
            <span style={{ fontStyle: "italic", color: "#FF9540" }}>
              everyday help
            </span>{" "}
            for your loved one
          </h1>
          <p
            style={{
              maxWidth: phone || tablet ? "90%" : "100%",
              fontSize: phone || tablet ? "1rem" : "1.1rem",
              lineHeight: 1.5,
              textAlign: phone ? "center" : "left",
            }}
          >
            For the times when you can’t be there, find fast, reliable help for
            your older loved ones on Herewith.
            <br /> <br />
            Set your own budget and book as much or as little help as you need.
            Get help with different tasks all in one place, from errands to
            companionship, tech support, weekly chores, and more.
          </p>
          <Link to={`https://app.herewith.com/sign-up${utm}`}>
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
              sx={{
                width: "fit-content",
                height: "3rem",
                fontSize: "18px",
                backgroundColor: "#FDBB2E",
                color: "black",
                marginTop: "2rem",
                "&:hover": {
                  background: "#FECA5B",
                  // Add other hover styles as needed
                },
              }}
            >
              Get started today
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default PartnerHeader;
