import * as React from "react";

import Fab from "@mui/material/Fab";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import UpIcon from "@mui/icons-material/KeyboardArrowUp";
interface BackToTopButtonProps {}

const BackToTopButton: React.FC<BackToTopButtonProps> = ({}) => {
  const theme = useTheme();

  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  const scrollToTop = () => {
    console.log("clcick");
    window.scrollTo(0, 0);
  };

  <button onClick={scrollToTop}>Scroll to Top</button>;
  return (
    <Fab
      color="primary"
      aria-label="add"
      sx={{
        zIndex: 1000,
        position: "fixed",
        right: 20,
        bottom: 20,
        display: phone ? "block" : "none",
      }}
      onClick={scrollToTop}
    >
      <UpIcon />
    </Fab>
  );
};

export default BackToTopButton;
