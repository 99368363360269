import * as React from "react";
import { Box, Button, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Stack from "@mui/material/Stack";

interface FastConvenientProps {}

const FastConvenient: React.FC<FastConvenientProps> = ({}) => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  // const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  const pencilIcon = require("../../assets/EventsIcon3.png");
  const calendarIcon = require("../../assets/EventsIcon1.png");
  const cashIcon = require("../../assets/EventsIcon2.png");
  return (
    <Box
      sx={{
        width: "100%",
        boxSizing: "border-box",

        minHeight: phone ? "85vh" : "32rem",
        height: "fit-content",
        display: "flex",
        flexDirection: "column",
        justifyContent: phone ? "center" : "space-evenly",
        alignItems: "center",
        margin: phone ? "1rem auto" : " 0 auto",
        backgroundColor: "white",
        padding: phone ? 0 : tablet ? "3rem" : "5rem ",
        borderTop: phone ? "none" : "1px solid #EBE9E6",
      }}
    >
      <h1
        style={{
          fontSize: phone ? "1.75rem" : "2.5rem",
          marginBottom: phone ? "1rem" : "2rem",
          marginTop: phone ? "1rem" : "0",
        }}
      >
        <i style={{ color: "#FF9540" }}>Get fast</i>, convenient help for your
        family
      </h1>

      <Stack
        spacing={{ xs: 1, sm: 2, md: 4 }}
        sx={{
          margin: "0 auto",
          flexDirection: phone ? "column" : tablet ? "row" : "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: phone ? "90%" : "30%",
          }}
        >
          <img
            src={pencilIcon}
            alt="pencil icon"
            style={{ width: tablet ? "50%" : "30%", margin: "1.25rem" }}
          />
          <h3 style={{ color: "#0C3D4C", lineHeight: 2 }}>
            Create a Help Request
          </h3>
          <p>Request a Helper for one-time tasks or ongoing help</p>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: phone ? "90%" : "30%",
          }}
        >
          <img
            src={calendarIcon}
            alt="pencil icon"
            style={{ width: tablet ? "50%" : "30%", margin: "1.25rem" }}
          />
          <h3 style={{ color: "#0C3D4C", lineHeight: 2 }}>Book your Helper</h3>
          <p>
            Chat with applicants online and book your Helper with just a few
            taps
          </p>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: phone ? "90%" : "30%",
          }}
        >
          <img
            src={cashIcon}
            alt="pencil icon"
            style={{ width: tablet ? "50%" : "30%", margin: "1.25rem" }}
          />
          <h3 style={{ color: "#0C3D4C", lineHeight: 2 }}>Pay-as-you-go</h3>
          <p>
            Only pay for the help you receive, with no commitments or
            subscriptions
          </p>
        </Box>
      </Stack>
    </Box>
  );
};

export default FastConvenient;
