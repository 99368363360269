import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  MemoryRouter,
} from "react-router-dom";


const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 200,

  [theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
    height: 100,
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "1rem",
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0,
  transition: theme.transitions.create("opacity"),
}));

const ImageMarked = styled("span")(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: "absolute",
  bottom: -2,
  left: "calc(50% - 9px)",
  transition: theme.transitions.create("opacity"),
}));

interface LinkProps {
  title: "string";
  url: "string";
  image: "string";
}

const RelatedButton: React.FC<LinkProps> = ({ title, image, url }) => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      component={RouterLink}
      to={url}
      sx={{
        display: "flex",
        flexWrap: "wrap",
        minWidth: phone? "117px" : "306px",
        width: phone? "117px" : "306px",
        height: phone? "88px" : "212px",
        borderRadius: phone ? "16px" : "24px",
        overflow: "hidden",
      }}
    >
      <ImageButton
        focusRipple
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <ImageSrc style={{ backgroundImage: `url(${image})` }} />
        <ImageBackdrop className="MuiImageBackdrop-root" />
        <Image>
        </Image>
      </ImageButton>
      <h2>
            {title}
            <ImageMarked className="MuiImageMarked-root" />
      </h2>
    </Box>
  );
};
export default RelatedButton;
