import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import Navbar from "../components/Navbar";

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
        <meta
          name="description"
          content="Read Herewith’s full Privacy Policy here."
        />
      </Helmet>

      <Box
        sx={{
          padding: "0 5rem 3rem  5rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <Navbar color="black" />
        <h1 style={{ marginBottom: "2rem" }}>Privacy Policy</h1>
        <iframe
          title="privacy"
          id="myFrame"
          referrerPolicy="origin"
          src="https://docs.google.com/document/d/e/2PACX-1vRiVQb1GTO-nX-feEuxBfUx68bGZ_sQar98ahn6Hz2FmGVwABPAMJ3XZne0fovBQVKlYDQyXrlG7Vcg/pub?embedded=true"
          height="fit-content"
          allowFullScreen={true}
          width="1000px"
          style={{
            maxWidth: "90vw",
            minHeight: "60vh",
            flex: 1,
            flexGrow: 1,
            margin: 0,
            border: "1px solid lightgray",
            borderRadius: "1rem",
            fontSize: "5rem",
            padding: "none !important",
            //   overflow: "auto",
          }}
        ></iframe>
      </Box>
    </>
  );
};

export default PrivacyPolicy;
