import * as React from "react";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Navbar from "../../components/Navbar";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";

interface PartnerHeaderProps {
  partner: string;
  showLogo: boolean;
}

const NewPartnerHeader: React.FC<PartnerHeaderProps> = ({ partner, showLogo }) => {
  const theme = useTheme();
  const [utm, setUTM] = useState(
    "/?utm_campaign=facility_launch_24&utm_source=events&utm_medium=landing_page&utm_content=cta&utm_id=website"
  );
  const [logo, setLogo] = useState("");
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));

  const promo = require("../../assets/Partners/CompressedWebP/BannerDesktop.webp");
  const promoMbl = require("../../assets/Partners/CompressedWebP/BannerMobile.webp");
  const promoTablet = require("../../assets/Partners/CompressedWebP/BannerTablet.webp");
  const headerimg = require("../../assets/Partners/CompressedWebP/PartnerHeroDesktop.webp");
  const mobileHeaderImg = require("../../assets/Partners/CompressedWebP/PartnerHeroMobile.webp");

  const ebgLogo = require("../../assets/ebg-logo.png");
  const benehealthLogo = require("../../assets/beneplace-logo.png");
  const benefithubLogo = require("../../assets/benefithub.png");
  const fondLogo = require("../../assets/Fond_LogoLockup.png");

  useEffect(() => {
    if (partner === "ebg") {
      setLogo(ebgLogo);
      setUTM(
        "/?utm_campaign=benefits_launch_24&utm_source=ebg&utm_medium=landing_page&utm_content=cta&utm_id=employee_benefits"
      );
    }
    if (partner === "beneplace") {
      setLogo(benehealthLogo);
      setUTM(
        "/?utm_campaign=benefits_launch_24&utm_source=beneplace&utm_medium=landing_page&utm_content=cta&utm_id=employee_benefits"
      );
    }
    if (partner === "benefithub") {
      setLogo(benefithubLogo);
      setUTM(
        "/?utm_campaign=benefits_launch_24&utm_source=benefithub&utm_medium=landing_page&utm_content=cta&utm_id=employee_benefits"
      );
    }

    if (partner === "amac") {
      setUTM(
        "/?utm_campaign=benefits_launch_24&utm_source=AMAC&utm_medium=landing_page&utm_content=cta&utm_id=employee_benefits"
      );
    }

    if (partner === "gateway") {
      setUTM(
        "/?utm_campaign=benefits_launch_24&utm_source=rewardgateway&utm_medium=landing_page&utm_content=cta&utm_id=employee_benefits"
      );
    }

    if (partner === "vantage") {
      setUTM(
        "/?utm_campaign=benefits_launch_24&utm_source=vantagecircle&utm_medium=landing_page&utm_content=cta&utm_id=employee_benefits"
      );
    }
    if (partner === "bennie") {
      setUTM(
        "/?utm_campaign=benefits_launch_24&utm_source=bennie_health&utm_medium=landing_page&utm_content=cta&utm_id=employee_benefits"
      );
    }
    if (partner === "fond") {
        setLogo(fondLogo)
      setUTM(
        "/?utm_campaign=benefits_launch_24&utm_source=fond&utm_medium=landing_page&utm_content=cta&utm_id=employee_benefits"
      );
    }
  }, [partner]);

  return (
    <>
      <Navbar color="white" />

      {/* Top Banner */}
      <Link to={`https://app.herewith.com/sign-up${utm}`}>
        <img
          src={promo}
          alt="promotional banner - get a free hour of service"
          loading="lazy"
          style={{ display: phone || tablet ? "none" : "block", width: "100vw" }}
        />
      </Link>

      <Link to={`https://app.herewith.com/sign-up${utm}`}>
        <img
          src={promoTablet}
          alt="promotional banner - get a free hour of service"
          loading="lazy"
          style={{
            display: phone ? "none" : tablet ? "flex" : "none",
            width: "100vw",
          }}
        />
      </Link>

      <Link to={`https://app.herewith.com/sign-up${utm}`}>
        <img
          src={promoMbl}
          alt="promotional banner - get a free hour of service"
          loading="lazy"
          style={{
            display: phone ? "block" : "none",
            borderBottom: "2px solid white",
            width: "100vw",
          }}
        />
      </Link>

      <Box
        sx={{
          width: "100%",
          height: phone ? "42rem" : tablet ? "38rem" : "44rem",
          minHeight: phone || tablet ? "fit-content" : "100%",
          backgroundImage: `url(${phone ? mobileHeaderImg : headerimg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Box
          sx={{
            width: phone ? "90%" : tablet ? "45%" : "38%",
            minHeight: phone || tablet ? "fit-content" : "100%",
            margin: phone ? "1rem" : tablet ? "1rem 0 0 3rem" : "0 0 0 5rem ",
            display: "flex",
            flexDirection: "column",
            alignItems: phone ? "center" : "space-between",
            justifyContent: phone ? "space-evenly" : "center",
            textAlign: phone ? "center" : "left",
            paddingBottom: tablet ? "5rem" : 0,
          }}
        >
          {showLogo && (
            <img
              src={logo}
              alt="logo"
              loading="lazy"
              style={{
                width: phone || tablet ? "65%" : "70%",
                marginBottom: phone ? "0" : "2rem",
                marginTop: phone || tablet ? "1.5rem" : 0,
              }}
            />
          )}

          <h1
            style={{
              marginBottom: phone ? "1rem" : "2rem",
              marginTop: phone ? "2rem" : 0,
              fontSize: phone ? "28px" : tablet ? "28px" : "40px",
            }}
          >
            <span style={{ fontStyle: "italic", color: "#795EC6"}}>
              Get $30 of everyday help
            </span>{" "}
            for your senior loved one
          </h1>
          <p
            style={{
              maxWidth: phone || tablet ? "90%" : "100%",
              fontSize: phone || tablet ? "16px" : "16px",
              lineHeight: 1.5,
              textAlign: phone ? "center" : "left",
            }}
          >
            For the times when you can’t be there, find fast, reliable help for
            your family on Herewith.
            Set your own budget and book as much or as little help as you need, 
            with everything from weekly chores to companionship, outings, errands, and even basic tech help.
          </p>
          <Link to={`https://app.herewith.com/sign-up${utm}`}>
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
              sx={{
                width: "fit-content",
                height: "3rem",
                fontSize: "18px",
                backgroundColor: "#FDBB2E",
                color: "black",
                marginTop: "2rem",
                boxShadow: "none !important", // Remove box shadow
                transition: "background 0.5s ease", // Smooth transition for background color
                "&:hover": {
                    background: "#E59101",
                    "& #buttontext": {
                        paddingRight: "5px", // Shift text on hover
                    },
                    },
                    "& #buttontext": {
                    transition: "padding-right 0.5s ease", // Smooth return when hover ends
                },
            }}
            >
              <span id="buttontext">Claim your welcome offer</span>
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default NewPartnerHeader;
