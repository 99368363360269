import * as React from "react";
import { Button, Box } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";

interface TrackHelpRequestsProps {}

const TrackHelpRequests: React.FC<TrackHelpRequestsProps> = () => {
  const curve = require("../../assets/Intersect.png");
  const whitecurve = require("../../assets/Intersect-white.png");
  const image = require("../../assets/Home/CompressedWebP/phoneblock.webp");
  const apple = require("../../assets/apple.png");
  const google = require("../../assets/google.png");
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  const mobileGrayBg = require("../../assets/MobileTrackBg.png");
  const mobilePhone = require("../../assets/Home/CompressedWebP/MobileTrackPhoneImg.webp");
  const tabletPhone = require("../../assets/Home/CompressedWebP/MobileTrackTablet.webp");
  return (
    <>
      <Box
        sx={{
          width: "100%",
          minHeight: "40rem",
          display: phone ? "none" : tablet ? "none" : "block",
        }}
      >
        <Box
          sx={{
            width: "100%",
            minHeight: "40rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: "white",
          }}
        >
          <img src={curve} alt="background" style={{ minHeight: "8rem" }} />
          <Box
            sx={{
              minHeight: "30rem",
              backgroundColor: "#FAF8F5",
              flex: 1,

              display: "flex",
              padding: "2rem 0",
            }}
          >
            <Box
              sx={{
                width: "85%",
                height: "inherit",
                backgroundImage: `url(${image})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                margin: "0 auto",
                borderRadius: "1rem",
                padding: "3rem",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  textAlign: "left",
                  padding: "1rem",
                }}
              >
                <h1
                  style={{
                    fontSize: "2.75rem",
                    lineHeight: 1.25,
                    marginBottom: "1rem",
                  }}
                >
                  Track Help Requests in real time
                </h1>
                <p
                  style={{
                    fontSize: "1rem",
                    lineHeight: 1.5,
                    marginBottom: "1.5rem",
                    color: "#0C3D4C",
                  }}
                >
                  Keep tabs on your Help Requests, with the Herewith mobile app.
                  Message and interview Helpers, manage payments, and get live
                  shift updates, all from one convenient place.
                </p>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <Link
                    style={{
                      width: "45% !important",
                      maxWidth: "45% !important",
                      minWidth: "45% !important",
                    }}
                    to="https://apps.apple.com/app/herewith-find-in-home-care/id1629912951"
                  >
                    <Button
                      sx={{
                        width: "100% !important",
                        // maxWidth: "45% !important",
                        // minWidth: "45% !important",
                      }}
                    >
                      <img
                        src={apple}
                        alt="apple"
                        style={{ maxWidth: "100%" }}
                      />
                    </Button>
                  </Link>
                  <Link
                    style={{
                      width: "45% !important",
                      maxWidth: "45% !important",
                      minWidth: "45% !important",
                    }}
                    to="https://play.google.com/store/apps/details?id=com.quoori.care.family.app"
                  >
                    <Button style={{ maxWidth: "100%" }}>
                      <img
                        src={google}
                        alt="apple"
                        style={{ maxWidth: "100%" }}
                      />
                    </Button>
                  </Link>
                </div>
              </Box>
            </Box>
          </Box>
          <Box sx={{ backgroundColor: "#FAF8F5", paddingTop: "2rem" }}>
            <img src={whitecurve} alt="background" style={{ width: "100%" }} />
          </Box>
        </Box>
      </Box>

      {/* --------------- tablet ---------------- */}

      <Box
        sx={{
          width: "100%",
          minHeight: "40rem",
          display: phone ? "none" : tablet ? "block" : "none",
        }}
      >
        <Box
          sx={{
            width: "100%",
            minHeight: "40rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: "white",
          }}
        >
          <img src={curve} alt="background" style={{ minHeight: "8rem" }} />
          <Box
            sx={{
              minHeight: "30rem",
              backgroundColor: "#FAF8F5",
              flex: 1,

              display: "flex",
              padding: "2rem 0",
            }}
          >
            <Box
              sx={{
                width: "90%",
                height: "26rem",
                minHeight: "fit-content",
                backgroundImage: `url(${tabletPhone})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                margin: "0 auto",
                borderRadius: "1rem",
                padding: "1.5rem",
              }}
            >
              <Box
                sx={{
                  width: "65%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  textAlign: "left",
                  padding: "1rem",
                }}
              >
                <h1
                  style={{
                    fontSize: "2rem",
                    lineHeight: 1.25,
                    marginBottom: "1rem",
                  }}
                >
                  Track Help Requests in real time
                </h1>
                <p
                  style={{
                    fontSize: "1rem",
                    lineHeight: 1.5,
                    marginBottom: "1.5rem",
                    color: "#0C3D4C",
                    width: "80%",
                  }}
                >
                  Keep tabs on your Help Requests, with the Herewith mobile app.
                  Message and interview Helpers, manage payments, and get live
                  shift updates, all from one convenient place.
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "45%",
                  }}
                >
                  <Link
                    style={{
                      width: "35% !important",
                      maxWidth: "35% !important",
                      minWidth: "35% !important",
                    }}
                    to="https://apps.apple.com/app/herewith-find-in-home-care/id1629912951"
                  >
                    <Button
                      sx={{
                        width: "100% !important",
                        // maxWidth: "45% !important",
                        // minWidth: "45% !important",
                      }}
                    >
                      <img
                        src={apple}
                        alt="apple"
                        style={{ maxWidth: "100%" }}
                      />
                    </Button>
                  </Link>
                  <Link
                    style={{
                      width: "35% !important",
                      maxWidth: "35% !important",
                      minWidth: "35% !important",
                    }}
                    to="https://play.google.com/store/apps/details?id=com.quoori.care.family.app"
                  >
                    <Button style={{ maxWidth: "100%" }}>
                      <img
                        src={google}
                        alt="apple"
                        style={{ maxWidth: "100%" }}
                      />
                    </Button>
                  </Link>
                </div>
              </Box>
            </Box>
          </Box>
          <Box sx={{ backgroundColor: "#FAF8F5", paddingTop: "2rem" }}>
            <img src={whitecurve} alt="background" style={{ width: "100%" }} />
          </Box>
        </Box>
      </Box>

      {/* --------------- mobile ---------------- */}

      <Box
        sx={{
          display: phone ? "flex" : "none",

          backgroundImage: `url(${mobileGrayBg})`,
          margin: "0 auto",
          width: "100vw",
          minHeight: "100vh",
          backgroundPosition: "center top",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover ",
          padding: 0,
          backgroundColor: "white",
          alignItems: "center",
        }}
      >
        {/* phone container */}

        <Box
          sx={{
            display: "flex",
            backgroundImage: `url(${mobilePhone})`,
            margin: "3rem auto 1.5rem auto",
            width: "90%",
            height: "fit-content",
            minHeight: "85vh",
            backgroundPosition: "center bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover ",
            padding: "1.5rem",
            backgroundColor: "transparent",
            borderRadius: "2.5rem",
            flexDirection: "column",
            paddingBottom: "48vh",
            alignItems: "center",
          }}
        >
          <h1
            style={{
              fontSize: "1.75rem",
              lineHeight: 1.25,
              marginBottom: "1rem",
            }}
          >
            Track Help Requests in real time
          </h1>
          <p
            style={{
              fontSize: "1rem",
              lineHeight: 1.5,
              marginBottom: "1.5rem",
              color: "#0C3D4C",
            }}
          >
            Keep tabs on your Help Requests, with the Herewith mobile app.
            Message and interview Helpers, manage payments, and get live shift
            updates, all from one convenient place.
          </p>

          {/* buttons */}

          <Link to="https://apps.apple.com/app/herewith-find-in-home-care/id1629912951">
            <Button sx={{ width: "75%", minWidth: "12rem !important" }}>
              <img
                src={apple}
                alt="apple"
                style={{ maxWidth: "100%" }}
                loading="lazy"
              />
            </Button>
          </Link>
          <Link to="https://play.google.com/store/apps/details?id=com.quoori.care.family.app">
            <Button sx={{ width: "75%", minWidth: "12rem !important" }}>
              <img
                src={google}
                alt="apple"
                style={{ maxWidth: "100%" }}
                loading="lazy"
              />
            </Button>
          </Link>

          {/* <Box sx={{ height: "40vh", border: "1px solid red" }}></Box> */}
        </Box>
      </Box>
    </>
  );
};

export default TrackHelpRequests;
