import SvgIcon from "@mui/material/SvgIcon";

export default function TimeIcon() {
  return (
    <SvgIcon
      sx={{
        width: "inherit",
        flex: 1,
        height: "50%",
      }}
    >
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="48" height="48" rx="24" fill="#D8F5DA" />
        <circle opacity="0.5" cx="24" cy="24" r="12" fill="#466647" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 15.5C24.8284 15.5 25.5 16.1716 25.5 17V24C25.5 24.1574 25.5741 24.3056 25.7 24.4L28.9 26.8C29.5627 27.2971 29.6971 28.2373 29.2 28.9C28.7029 29.5627 27.7627 29.6971 27.1 29.2L23.9 26.8C23.0187 26.139 22.5 25.1017 22.5 24V17C22.5 16.1716 23.1716 15.5 24 15.5Z"
          fill="#466647"
        />
      </svg>
    </SvgIcon>
  );
}
