import * as React from "react";
import { styled, alpha } from "@mui/material/styles";

import Box from "@mui/material/Box";

import InputBase from "@mui/material/InputBase";
import { Link } from "react-router-dom";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Button } from "@mui/material";
const Search = styled("div")(({ theme }) => ({
  position: "relative",

  backgroundColor: alpha(theme.palette.common.white, 1),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.95),
  },
  marginLeft: 0,
  marginTop: "2.5rem",
  width: "60% !important",
  height: "4rem",
  borderRadius: "9999px",
  display: "flex",
  padding: "8px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: 0,
    width: "100% !important",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  //   padding: theme.spacing(0, 2),
  height: "80%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "3rem",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  flex: 1,
  height: "100%",
  borderRadius: "9999px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    height: "100%",
    fontSize: "1.25rem",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
}));
export default function Header() {
  const theme = useTheme();

  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  const bgimage = require("../../assets/Home/CompressedWebP/blueheaderimage.webp");
  const bgimageTablet = require("../../assets/Home/CompressedWebP/headerimgtablet.webp");
  const bgImgMobile = require("../../assets/Home/CompressedWebP/MobileHomeHero.webp");
  return (
    <>
      <div
        style={{
          display: phone ? "none" : tablet ? "none" : "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
          backgroundImage: `url(${bgimage})`,
          margin: "2rem auto",
          width: "90%",
          height: "36rem",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderRadius: "2.5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-around",
            height: "inherit",
            margin: "2rem auto",
            width: "90%",
          }}
        >
          <Box sx={{ maxWidth: "60%", textAlign: "left" }}>
            <h1 style={{ fontSize: "3.5rem" }}>
              Find <span style={{ fontStyle: "italic" }}>everyday help</span> at
              home for seniors
            </h1>
            {/* <Search>
              <SearchIconWrapper>
                <LocationOnRoundedIcon
                  style={{ fontSize: "2rem", color: "#177A98" }}
                />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Enter your zip code"
                inputProps={{ "aria-label": "search" }}
              />
              <Button
                variant="contained"
                disableElevation
                sx={{
                  borderRadius: "9999px",
                  fontSize: "18px",
                  "&:hover": {
                    background: "#FECA5B",
                    // Add other hover styles as needed
                  },
                }}
              >
                Find a Helper
              </Button>
            </Search> */}
            <Link to="https://app.herewith.com/sign-up/?utm_campaign=facility_launch_24&utm_source=family_home&utm_medium=page&utm_content=cta&utm_id=website">
              <Button
                variant="contained"
                endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
                sx={{
                  minWidth: phone ? "fit-content" : "fit-content",
                  width: phone ? "100%" : "fit-content",
                  height: "3.5rem",
                  marginTop: "2rem",
                  borderRadius: 9999,
                  fontSize: "18px",
                  "&:hover": {
                    background: "#FECA5B",
                    // Add other hover styles as needed
                  },
                }}
              >
                Find a Helper
              </Button>
            </Link>
          </Box>
          <a href="/helpers">
            <Button
              variant="text"
              endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
              sx={{
                textTransform: "none",
                fontSize: "1.25rem",
                color: "#177A98",
                borderBottom: "1px solid #177A98",
                borderRadius: "0",
                paddingLeft: 0,
                paddingRight: 0,
                width: "fit-content",

                "&:hover": {
                  backgroundColor: "transparent",
                  color: "#0C3D4C",
                  borderColor: "#0C3D4C",
                },
              }}
            >
              Become a Helper instead
            </Button>
          </a>
        </Box>
        {/* <img src={bgimage} alt="background" /> */}
      </div>
      {/* ------------ tablet ------------ */}
      <div
        style={{
          display: phone ? "none" : tablet ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
          backgroundImage: `url(${bgimageTablet})`,
          margin: "2rem auto",
          width: "90%",
          height: "25rem",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderRadius: "2.5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-around",
            height: "inherit",
            margin: "2rem auto",
            width: "90%",
          }}
        >
          <Box sx={{ maxWidth: "80%", textAlign: "left" }}>
            <h1 style={{ fontSize: "2.5rem" }}>
              Find <span style={{ fontStyle: "italic" }}>everyday help</span> at
              home for seniors
            </h1>
            {/* <Search>
              <SearchIconWrapper>
                <LocationOnRoundedIcon
                  style={{ fontSize: "2rem", color: "#177A98" }}
                />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Enter your zip code"
                inputProps={{ "aria-label": "search" }}
              />
              <Button
                variant="contained"
                disableElevation
                sx={{
                  borderRadius: "9999px",
                  fontSize: "18px",
                  "&:hover": {
                    background: "#FECA5B",
                    // Add other hover styles as needed
                  },
                }}
              >
                Find a Helper
              </Button>
            </Search> */}
            <Link to="https://app.herewith.com/sign-up/?utm_campaign=facility_launch_24&utm_source=family_home&utm_medium=page&utm_content=cta&utm_id=website">
              <Button
                variant="contained"
                endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
                sx={{
                  minWidth: phone ? "fit-content" : "fit-content",
                  width: phone ? "100%" : "fit-content",
                  height: "2.75rem",
                  marginTop: "2rem",
                  borderRadius: 9999,
                  fontSize: "16px",
                  "&:hover": {
                    background: "#FECA5B",
                    // Add other hover styles as needed
                  },
                }}
              >
                Find a Helper
              </Button>
            </Link>
          </Box>
          <a href="/helpers">
            <Button
              variant="text"
              endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
              sx={{
                textTransform: "none",
                fontSize: "1rem",
                color: "#177A98",
                borderBottom: "1px solid #177A98",
                borderRadius: "0",
                paddingLeft: 0,
                paddingRight: 0,
                width: "fit-content",

                "&:hover": {
                  backgroundColor: "transparent",
                  color: "#0C3D4C",
                  borderColor: "#0C3D4C",
                },
              }}
            >
              Become a Helper instead
            </Button>
          </a>
        </Box>
        {/* <img src={bgimage} alt="background" /> */}
      </div>

      {/* ------------mobile------------ */}

      <div
        style={{
          display: phone ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          backgroundImage: `url(${bgImgMobile})`,
          margin: "2rem auto",
          width: "90%",
          height: "32rem",
          minHeight: "fit-content",
          backgroundPosition: "center bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: " cover",
          borderRadius: "2.5rem",
          padding: "2rem 1rem 0 1rem",
        }}
      >
        <h1 style={{ fontSize: "1.75rem" }}>
          Find <i>everyday help</i>
          <br /> at home for seniors
        </h1>
        {/* <Search>
          <SearchIconWrapper>
            <LocationOnRoundedIcon
              style={{ fontSize: "2rem", color: "#177A98" }}
            />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Enter your zip code"
            inputProps={{ "aria-label": "search" }}
          />
        </Search> */}
        <Link to="https://app.herewith.com/sign-up/?utm_campaign=facility_launch_24&utm_source=family_home&utm_medium=page&utm_content=cta&utm_id=website">
          <Button
            variant="contained"
            disableElevation
            sx={{
              borderRadius: "9999px",
              fontSize: "1.25rem",
              marginTop: "2rem",
              width: "80vw",
              height: "4rem",
              "&:hover": {
                background: "#FECA5B",
                // Add other hover styles as needed
              },
            }}
          >
            Find a helper
          </Button>
        </Link>

        <a href="/helpers">
          <Button
            variant="text"
            endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
            sx={{
              textTransform: "none",
              fontSize: "1rem",
              color: "#177A98",
              borderBottom: "1px solid #90C8D4",
              borderRadius: "0",
              paddingLeft: 0,
              paddingRight: 0,
              marginTop: "1.5rem",
              "&:hover": {
                backgroundColor: "transparent",
                color: "#177A98",
                borderColor: "#177A98",
              },
            }}
          >
            Become a Helper instead
          </Button>
        </a>
      </div>
    </>
  );
}
