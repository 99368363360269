import * as React from "react";
import Box from "@mui/material/Box";
import {
  Paper,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function CgFaqs() {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  const curve = require("../assets/graycurve.png");
  return (
    <Box
      sx={{
        width: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        backgrouundColor: "white",
        justifyContent: "space-between",
        minHeight: "fit-content",
        height: "fit-content",
      }}
    >
      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <img
          src={curve}
          alt="background"
          style={{ width: "100%", border: "none" }}
        />
      </Box>
      <Box
        sx={{
          width: "100vw",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          minHeight: "fit-content",
          justifyContent: "space-between",
          height: phone ? "fit-content" : "30rem",
          backgroundColor: "#FAF8F5 ",
          paddingTop: "2rem",
          paddingBottom: "2rem",
          border: "none",
        }}
      >
        <Paper
          sx={{
            width: "90%",
            backgroundColor: "white",
            // height: phone ? "fit-content" : "90%",
            minHeight: "fit-content",
            margin: "0 auto",
            borderRadius: "1rem",
            display: "flex",
            flexDirection: phone || tablet ? "column" : "row",
            alignItems: "flex-start",
            padding: phone
              ? "2rem 1rem"
              : tablet
              ? "3rem 3rem 1rem 3rem "
              : "3rem",
            justifyContent: "space-between",
          }}
        >
          <h1
            style={{
              display: phone || tablet ? "none" : "block",
              textAlign: "left",
            }}
          >
            Learn more <br />
            about Herewith
          </h1>
          <h1
            style={{
              fontSize: "1.75rem",
              display: phone ? "block" : "none",
              textAlign: "center",
              width: "100%",
              marginBottom: phone ? "1.25rem" : 0,
            }}
          >
            Learn more <br />
            about Herewith
          </h1>
          <h1
            style={{
              fontSize: "2rem",
              display: phone ? "none" : tablet ? "block" : "none",
              textAlign: "left",
              width: "100%",
              marginBottom: phone ? "1.25rem" : 0,
            }}
          >
            Learn more about Herewith
          </h1>

          <Box
            sx={{
              width: phone || tablet ? "100%" : "60%",
              height: "100%",
            }}
          >
            <Accordion
              elevation={0}
              sx={{ textAlign: "left", fontFamily: "Sitebody !important" }}
            >
              <AccordionSummary
                sx={{ paddingLeft: 0, padding: phone ? "0" : "1.25rem 0" }}
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography
                  sx={{
                    fontSize: phone ? "1.1rem" : "1.25rem",
                    color: "#0C3D4C",
                    fontWeight: "bold",
                    textAlign: "left",
                    fontFamily: "Sitebody !important",
                    marginLeft: phone ? "1rem" : 0,
                  }}
                >
                  How does Herewith work?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ fontFamily: "Sitebody !important" }}>
                  Herewith is a free platform where you can apply for jobs. The
                  first step is to create your Helper profile, so clients can
                  see your application, your skills and pay rate, and chat with
                  you. Once your profile is done, you can apply for one-time
                  tasks, like helping someone pick up their groceries, or
                  ongoing jobs helping your favorite clients with everyday
                  tasks.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion elevation={0} sx={{ textAlign: "left" }}>
              <AccordionSummary
                sx={{ padding: phone ? "0" : "1.25rem 0" }}
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography
                  sx={{
                    fontSize: phone ? "1.1rem" : "1.25rem",
                    color: "#0C3D4C",
                    fontWeight: "bold",
                    textAlign: "left",
                    fontFamily: "Sitebody !important",
                    marginLeft: phone ? "1rem" : 0,
                  }}
                >
                  How much can I make as a Helper?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ fontFamily: "Sitebody !important" }}>
                  Helpers make an average of $25-35/hour, but there are a lot of
                  ways to make more. It depends on your skills, what kind of
                  tasks you like to help with, and the typical wages for
                  caregivers where you live.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion elevation={0} sx={{ textAlign: "left" }}>
              <AccordionSummary
                sx={{ paddingLeft: 0, padding: phone ? "0" : "1.25rem 0" }}
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography
                  sx={{
                    fontSize: phone ? "1.1rem" : "1.25rem",
                    color: "#0C3D4C",
                    fontWeight: "bold",
                    textAlign: "left",
                    fontFamily: "Sitebody !important",
                    marginLeft: phone ? "1rem" : 0,
                  }}
                >
                  Can I set my own hours as a Helper?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ fontFamily: "Sitebody !important" }}>
                  You can work as much or as little as you want on Herewith. You
                  can set your availability on your profile and apply to jobs
                  and one-time tasks that work for your schedule. If you get
                  hired for an ongoing care job, you will chat with your client
                  to set your work schedule.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion elevation={0} sx={{ textAlign: "left" }}>
              <AccordionSummary
                sx={{ paddingLeft: 0, padding: phone ? "0" : "1.25rem 0" }}
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography
                  sx={{
                    fontSize: phone ? "1.1rem" : "1.25rem",
                    color: "#0C3D4C",
                    fontWeight: "bold",
                    textAlign: "left",
                    fontFamily: "Sitebody !important",
                    marginLeft: phone ? "1rem" : 0,
                  }}
                >
                  What kind of experience do I need?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ fontFamily: "Sitebody !important" }}>
                  The most important qualities in working with older adults are
                  patience, caring for others, good communication, and
                  friendliness. Many Helpers started out by caring for their own
                  family members or neighbors. Experience as a professional
                  caregiver, certificates, and skills like first aid training
                  are a bonus that can help you earn more per hour.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion elevation={0} sx={{ textAlign: "left" }}>
              <AccordionSummary
                sx={{
                  paddingLeft: 0,
                  padding: phone ? "0" : "1.25rem 0",
                  // border: "1px solid red",
                }}
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography
                  sx={{
                    fontSize: phone ? "1.1rem" : "1.25rem",
                    color: "#0C3D4C",
                    fontWeight: "bold",
                    textAlign: "left",
                    fontFamily: "Sitebody !important",
                    marginLeft: phone ? "1rem" : 0,
                  }}
                >
                  Is Herewith nationwide?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ fontFamily: "Sitebody !important" }}>
                  Founded in California, we are a growing, nationwide platform.
                  Not seeing jobs in your area? Don’t worry, we’ll notify you as
                  soon as new jobs and tasks come up.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
