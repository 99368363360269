import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";

import LockIcon from "@mui/icons-material/Lock";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function SimpleFooter() {
  const logo = require("../assets/FooterLogo.png");

  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Box>
    <Divider variant="fullWidth" sx={{ opacity: "0.5" }} />

    <Box
      sx={{
        width: "100%",
        // position: "absolute",
        // bottom: 0,
        display: "block",
        background: "white",
        boxSizing: "border-box",
        height: "40rem",
        minHeight: "fit-content",

        padding: phone ? "1rem" : tablet ? "2.5rem" : "3rem 5rem",
        color: "#706D67",
      }}
    >
      <AppBar
        elevation={0}
        position="static"
        sx={{
          background: "white",

          bottom: 0,
          display: "flex",
          flexDirection: phone ? "column" : tablet ? "column" : "row",
          width: "100%",

          alignItems: "flex-start",
          justifyContent: "space-between",
          padding: "0rem 0rem 3rem 0rem",
          margin: 0,
        }}
      >
        <Box sx={{ width: phone ? "100%" : "50%", textAlign: "left" }}>
          <Link
            style={{
              textDecoration: "none",
            }}
            to="/"
          >
            <img
              src={logo}
              style={{
                width: "158px",
              }}
              alt="logo"
            />
          </Link>
        </Box>
      </AppBar>
      <Divider variant="fullWidth" sx={{ opacity: "0.5" }} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: phone ? "column-reverse" : "row",
          justifyContent: phone ? "flex-start" : "space-between",
          alignItems: phone ? "flex-start" : "center",
          padding: phone ? "1rem" : "1rem 0",
        }}
      >
        {" "}
        <p style={{ fontSize: "1.25rem", marginTop: phone ? "2rem" : 0 }}>
          Copyright 2024
        </p>

      </Box>
      <Divider variant="fullWidth" sx={{ opacity: "0.5", display: phone ? "none" : "", }} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          fontSize: "1rem",
          textAlign: "left",
          alignItems: "center",
          padding: phone ? "2rem 1rem" : "2rem 0",
          lineHeight: 1.5,
        }}
      >
        <p>
        Herewith provides a platform to assist caregivers and care recipients in 
        connecting regarding in-home non-medical care opportunities. Herewith 
        does not employ any caregivers, nor does it recommend any caregivers 
        and/or care recipients who use its platform. User information provided 
        in profiles, posts, and otherwise on the Herewith platform is not generated 
        or verified by Herewith. Each user of Herewith’s platform is responsible for 
        conducting their own vetting before determining whether to enter into an employment 
        relationship and for their own conduct, including compliance with applicable laws.
        </p>
      </Box>
      <Divider variant="fullWidth" sx={{ opacity: "0.5" }} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: phone ? "column" : "row",
          fontSize: "1rem",
          textAlign: "left",
          alignItems: phone ? "flex-start" : "center",
          padding: "1rem 0",
        }}
      >
        <Chip
          icon={<LockIcon />}
          label="SSL"
          size="medium"
          sx={{ margin: phone ? "1rem" : "0 1rem 0 0", marginRight: "1rem", height: "36px", width: "76px"  }}
        />
        <Chip
          icon={<LockIcon />}
          label="CCPA Compliant"
          size="medium"
          sx={{ margin: phone ? "1rem" : "0 1rem 0 0", marginRight: "1rem", height: "36px", width: "158x" }}
        />
        <Divider orientation="vertical" flexItem />
        <p style={{ margin: "0 1rem", width: phone ? "100%" : "30%" }}>
          Herewith takes your privacy seriously. Please review our <a href="https://herewith.com/privacy">privacy
          policy</a> to understand how we use your data.
        </p>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ display: phone ? "none" : "block" }}
        />

        <p
          style={{
            margin: phone ? "1rem 0" : "0 1rem",
            width: phone ? "100%" : "30%",
            // borderTop: "1px solid lightgray",
            padding: phone ? "1rem" : 0,
          }}
        >
          Secure Payments with{" "}
          <span style={{ fontWeight: "bold", color: "#635BFF" }}>Stripe</span>
        </p>
      </Box>
    </Box>
    </Box>
  );
}
