import * as React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Avatar,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Phone } from "@mui/icons-material";
import { Link } from "react-router-dom";

interface HandleProps {}

const Handle: React.FC<HandleProps> = () => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  const content = require("../../assets/handle.png");

  // icons

  const icon1 = require("../../assets/cgPinkCheck.png");
  const icon2 = require("../../assets/cgBlueCalendar.png");
  const icon3 = require("../../assets/cgOrangePin.png");

  // social buttons

  const apple = require("../../assets/apple.png");
  const google = require("../../assets/google.png");

  // images
  const phoneImg = require("../../assets/cgDesktopPhone.png");
  const mobilePhoneImg = require("../../assets/cgDesktopPhone.png");
  const phoneVideo = require("../../assets/CaregiverVideo.mp4");
  return (
    <>
      <Box
        sx={{
          width: "100%",
          minHeight: "36rem",
          height: "fit-content",
          backgroundColor: "white",
          display: phone ? "none" : tablet ? "none" : "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "3rem 10rem",
          borderTop: "1px solid #EBE9E6",
          boxSizing: "border-box",
        }}
      >
        {/* Left column */}

        <Box
          sx={{
            width: "50%",

            aspectRatio: 1,

            padding: "0 3.5rem 3.5rem 3.5rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
            textAlign: "left",
          }}
        >
          {/* Headline */}

          <h1>
            <span
              style={{
                fontStyle: "italic",
                color: "#FF9540",
              }}
            >
              Handle everything
            </span>{" "}
            right from your phone
          </h1>

          {/* icon list */}

          <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
            <List>
              {/* bullet 1 */}

              <ListItem sx={{ padding: "0 1rem 1.5rem 0rem" }}>
                <ListItemAvatar>
                  <Avatar
                    src={icon1}
                    alt="heart icon"
                    variant="square"
                    sx={{
                      width: 40,
                      height: 40,
                      bgcolor: "transparent",
                      marginRight: "2rem",
                    }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primaryTypographyProps={{
                    lineHeight: 1.5,
                    fontSize: "1.1rem",
                    color: "#0C3D4C",
                    fontWeight: "bold",
                    fontFamily: "Sitebody",
                  }}
                  secondaryTypographyProps={{
                    lineHeight: 1.5,
                    fontSize: "1.1rem",
                    textAlign: "left",
                    fontFamily: "Sitebody",
                  }}
                  primary="Find tasks & jobs near you"
                  secondary="Build your profile and apply to jobs with just a few taps"
                />
              </ListItem>
              <Divider />

              {/* bullet 2 */}

              <ListItem sx={{ padding: "1.5rem 1rem 1.5rem 0rem" }}>
                <ListItemAvatar>
                  <Avatar
                    src={icon2}
                    alt="heart icon"
                    variant="square"
                    sx={{
                      width: 40,
                      height: 40,
                      bgcolor: "transparent",
                      marginRight: "2rem",
                    }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primaryTypographyProps={{
                    lineHeight: 1.5,
                    fontSize: "1.1rem",
                    color: "#0C3D4C",
                    fontWeight: "bold",
                    fontFamily: "Sitebody",
                  }}
                  secondaryTypographyProps={{
                    lineHeight: 1.5,
                    fontSize: "1.1rem",
                    textAlign: "left",
                    fontFamily: "Sitebody",
                  }}
                  primary="Instant booking for tasks and jobs"
                  secondary="Get updates straight to your phone when you get hired"
                />
              </ListItem>
              <Divider />

              {/* bullet 3 */}

              <ListItem sx={{ padding: "1.5rem 1rem 1.5rem 0rem" }}>
                <ListItemAvatar>
                  <Avatar
                    src={icon3}
                    alt="heart icon"
                    variant="square"
                    sx={{
                      width: 40,
                      height: 40,
                      bgcolor: "transparent",
                      marginRight: "2rem",
                    }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primaryTypographyProps={{
                    lineHeight: 1.5,
                    fontSize: "1.1rem",
                    color: "#0C3D4C",
                    fontWeight: "bold",
                    fontFamily: "Sitebody",
                  }}
                  secondaryTypographyProps={{
                    lineHeight: 1.5,
                    fontSize: "1.1rem",
                    textAlign: "left",
                    fontFamily: "Sitebody",
                  }}
                  primary="Job search anytime, anywhere"
                  secondary="Stay on top of your job search & applications on the go"
                />
              </ListItem>
            </List>
          </Box>

          {/* buttons */}

          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <Link
              style={{ width: "40%", minWidth: "40% !important" }}
              to="https://apps.apple.com/us/app/herewith-pro-for-caregivers/id1629914138"
            >
              <Button sx={{ width: "100%" }}>
                <img
                  src={apple}
                  alt="apple"
                  style={{ maxWidth: "100%" }}
                  loading="lazy"
                />
              </Button>
            </Link>
            <Link
              style={{ width: "40%", minWidth: "40% !important" }}
              to="https://play.google.com/store/apps/details?id=com.quoori.care.pro.app"
            >
              <Button sx={{ width: "100%" }}>
                <img
                  src={google}
                  alt="apple"
                  style={{ maxWidth: "100%" }}
                  loading="lazy"
                />
              </Button>
            </Link>
          </div>
        </Box>

        {/* Right column */}

        <Box
          sx={{
            width: "45%",
            display: "flex",
            alignItems: "center",
            height: "inherit",
          }}
        >
          <video
            // controls
            playsInline
            autoPlay
            muted
            loop
            poster={phoneImg}
            src={phoneVideo}
            style={{ width: "100%", borderRadius: "1.5rem" }}
          />
          {/* <img src={phoneImg} alt="photo of a phone" /> */}
        </Box>
      </Box>

      {/* ------------ tablet ------------ */}

      <Box
        sx={{
          width: "100%",
          minHeight: "36rem",
          backgroundColor: "white",

          display: phone ? "none" : tablet ? "flex" : "none",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "3rem 2rem",
          borderTop: "1px solid #EBE9E6",
        }}
      >
        {/* Left column */}

        <Box
          sx={{
            width: "50%",

            aspectRatio: 1,

            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
            textAlign: "left",
          }}
        >
          {/* Headline */}

          <h1 style={{ fontSize: "2rem" }}>
            <span
              style={{
                fontStyle: "italic",
                color: "#FF9540",
              }}
            >
              Handle everything
            </span>{" "}
            right from your phone
          </h1>

          {/* icon list */}

          <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
            <List>
              {/* bullet 1 */}

              <ListItem sx={{ padding: "0 1rem 1rem 0rem" }}>
                <ListItemAvatar>
                  <Avatar
                    src={icon1}
                    alt="heart icon"
                    variant="square"
                    sx={{
                      width: 35,
                      height: 35,
                      bgcolor: "transparent",
                      marginRight: "2rem",
                    }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primaryTypographyProps={{
                    lineHeight: 1.5,
                    fontSize: "1rem",
                    color: "#0C3D4C",
                    fontWeight: "bold",
                    fontFamily: "Sitebody",
                  }}
                  secondaryTypographyProps={{
                    lineHeight: 1.5,
                    fontSize: "1rem",
                    textAlign: "left",
                    fontFamily: "Sitebody",
                  }}
                  primary="Find tasks & jobs near you"
                  secondary="Build your profile and apply to jobs with just a few taps"
                />
              </ListItem>
              <Divider />

              {/* bullet 2 */}

              <ListItem sx={{ padding: "1.5rem 1rem 1rem 0rem" }}>
                <ListItemAvatar>
                  <Avatar
                    src={icon2}
                    alt="heart icon"
                    variant="square"
                    sx={{
                      width: 35,
                      height: 35,
                      bgcolor: "transparent",
                      marginRight: "2rem",
                    }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primaryTypographyProps={{
                    lineHeight: 1.5,
                    fontSize: "1rem",
                    color: "#0C3D4C",
                    fontWeight: "bold",
                    fontFamily: "Sitebody",
                  }}
                  secondaryTypographyProps={{
                    lineHeight: 1.5,
                    fontSize: "1rem",
                    textAlign: "left",
                    fontFamily: "Sitebody",
                  }}
                  primary="Instant booking for tasks and jobs"
                  secondary="Get updates straight to your phone when you get hired"
                />
              </ListItem>
              <Divider />

              {/* bullet 3 */}

              <ListItem sx={{ padding: "1.5rem 1rem 1rem 0rem" }}>
                <ListItemAvatar>
                  <Avatar
                    src={icon3}
                    alt="heart icon"
                    variant="square"
                    sx={{
                      width: 35,
                      height: 35,
                      bgcolor: "transparent",
                      marginRight: "2rem",
                    }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primaryTypographyProps={{
                    lineHeight: 1.5,
                    fontSize: "1rem",
                    color: "#0C3D4C",
                    fontWeight: "bold",
                    fontFamily: "Sitebody",
                  }}
                  secondaryTypographyProps={{
                    lineHeight: 1.5,
                    fontSize: "1rem",
                    textAlign: "left",
                    fontFamily: "Sitebody",
                  }}
                  primary="Job search anytime, anywhere"
                  secondary="Stay on top of your job search & applications on the go"
                />
              </ListItem>
            </List>
          </Box>

          {/* buttons */}

          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <Link
              style={{ width: "50%", minWidth: "50% !important" }}
              to="https://apps.apple.com/us/app/herewith-pro-for-caregivers/id1629914138"
            >
              <Button sx={{ width: "100%" }}>
                <img src={apple} alt="apple" style={{ maxWidth: "100%" }} />
              </Button>
            </Link>
            <Link
              style={{ width: "50%", minWidth: "50% !important" }}
              to="https://play.google.com/store/apps/details?id=com.quoori.care.pro.app"
            >
              <Button sx={{ width: "100%" }}>
                <img src={google} alt="apple" style={{ maxWidth: "100%" }} />
              </Button>
            </Link>
          </div>
        </Box>

        {/* Right column */}

        <Box
          sx={{
            width: "48%",
            display: "flex",
            alignItems: "center",
            height: "inherit",
          }}
        >
          <video
            // controls
            playsInline
            autoPlay
            muted
            loop
            poster={phoneImg}
            src={phoneVideo}
            style={{ width: "100%", borderRadius: "1.5rem" }}
          />
          {/* <img src={phoneImg} alt="photo of a phone" /> */}
        </Box>
      </Box>

      {/* ------------ mobile ------------ */}

      <Box
        sx={{
          width: "100%",
          minHeight: "120vh",
          backgroundColor: "white",
          display: phone ? "flex" : "none",
          flexDirection: "column",
          // justifyContent: "space-between",
          borderTop: "1px solid #EBE9E6",
          padding: "1.5rem 1rem",
        }}
      >
        {/* image */}

        {/* <img src={mobilePhoneImg} alt="phone" style={{ minHeight: "55vh" }} /> */}
        <video
          // controls
          playsInline
          autoPlay
          muted
          loop
          poster={phoneImg}
          src={phoneVideo}
          style={{ width: "100%", borderRadius: "1rem" }}
        />
        {/* headline */}

        <h1 style={{ fontSize: "1.75rem", margin: "2rem auto" }}>
          <span style={{ fontStyle: "italic", color: "#FF9540" }}>
            Handle everything
          </span>{" "}
          right from your phone
        </h1>

        {/* icon grid */}

        <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
          <List>
            {/* bullet 1 */}

            <ListItem sx={{ padding: "0 1rem 1.5rem 0rem" }}>
              <ListItemAvatar>
                <Avatar
                  src={icon1}
                  alt="heart icon"
                  variant="square"
                  sx={{
                    width: 40,
                    height: 40,
                    bgcolor: "transparent",
                    margin: "0 2rem 0 1rem",
                  }}
                />
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{
                  lineHeight: 1.5,
                  fontSize: "1.1rem",
                  color: "#0C3D4C",
                  fontWeight: "bold",
                  fontFamily: "Sitebody",
                }}
                secondaryTypographyProps={{
                  lineHeight: 1.5,
                  fontSize: "1.1rem",

                  fontFamily: "Sitebody",
                }}
                primary="Find tasks & jobs near you"
                secondary="Build your profile and apply to jobs with just a few taps"
              />
            </ListItem>
            <Divider />

            {/* bullet 2 */}

            <ListItem sx={{ padding: "1.5rem 1rem 1.5rem 1rem" }}>
              <ListItemAvatar>
                <Avatar
                  src={icon2}
                  alt="heart icon"
                  variant="square"
                  sx={{
                    width: 40,
                    height: 40,
                    bgcolor: "transparent",
                    marginRight: "2rem",
                  }}
                />
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{
                  lineHeight: 1.5,
                  fontSize: "1.1rem",
                  color: "#0C3D4C",
                  fontWeight: "bold",
                  fontFamily: "Sitebody",
                }}
                secondaryTypographyProps={{
                  lineHeight: 1.5,
                  fontSize: "1.1rem",

                  fontFamily: "Sitebody",
                }}
                primary="Instant booking for tasks and jobs"
                secondary="Get updates straight to your phone when you get hired"
              />
            </ListItem>
            <Divider />

            {/* bullet 3 */}

            <ListItem sx={{ padding: "1.5rem 1rem 1.5rem 1rem" }}>
              <ListItemAvatar>
                <Avatar
                  src={icon3}
                  alt="heart icon"
                  variant="square"
                  sx={{
                    width: 40,
                    height: 40,
                    bgcolor: "transparent",
                    marginRight: "2rem",
                  }}
                />
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{
                  lineHeight: 1.5,
                  fontSize: "1.1rem",
                  color: "#0C3D4C",
                  fontWeight: "bold",
                  fontFamily: "Sitebody",
                }}
                secondaryTypographyProps={{
                  lineHeight: 1.5,
                  fontSize: "1.1rem",

                  fontFamily: "Sitebody",
                }}
                primary="Job search anytime, anywhere"
                secondary="Stay on top of your job search & applications on the go"
              />
            </ListItem>
          </List>
        </Box>

        {/* app store buttons */}

        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Link
            style={{ width: "60%", minWidth: "60% !important" }}
            to="https://apps.apple.com/us/app/herewith-pro-for-caregivers/id1629914138"
          >
            <Button sx={{ width: "100%" }}>
              <img
                src={apple}
                alt="apple"
                style={{ maxWidth: "100%" }}
                loading="lazy"
              />
            </Button>
          </Link>
          <Link
            style={{ width: "60%", minWidth: "60% !important" }}
            to="https://play.google.com/store/apps/details?id=com.quoori.care.pro.app"
          >
            <Button sx={{ width: "100%" }}>
              <img
                src={google}
                alt="apple"
                style={{ maxWidth: "100%" }}
                loading="lazy"
              />
            </Button>
          </Link>
        </div>
      </Box>
    </>
  );
};

export default Handle;
