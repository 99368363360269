import * as React from "react";
import Box from "@mui/material/Box";
import { Paper } from "@mui/material";

import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Partners() {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  const curve = require("../assets/graycurve.png");
  const logos = require("../assets/Partners/CompressedWebP/partnerlogos.webp");
  const mobilelogos = require("../assets/Partners/CompressedWebP/partnerlogosmobile.webp");

  return (
    <>
      <Box
        sx={{
          width: "100%",

          display: phone ? "none" : "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: tablet ? "15rem" : "20rem",
          backgroundColor: " #FAF8F5        ",
        }}
      >
        <h1 style={{ fontSize: tablet ? "2rem" : "2.5rem" }}>Our partners</h1>
        <Box
          sx={{
            width: tablet ? "100%" : "80%",
            display: "flex",
            justifyContent: "space-evenly",

            marginTop: "2rem",
          }}
        >
          <img
            src={logos}
            alt=""
            style={{ maxWidth: "90% !important", width: "90%" }}
          />
          {/* <Stack direction="row" spacing={2}>
            <Item sx={{ width: "10rem", height: "3rem" }}>
              <Box
                sx={{
                  backgroundColor: "#F9B49F",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                Placeholder
              </Box>
            </Item>
            <Item sx={{ width: "10rem", height: "3rem" }}>
              <Box
                sx={{
                  backgroundColor: "#F9B49F",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                Placeholder
              </Box>
            </Item>{" "}
            <Item sx={{ width: "10rem", height: "3rem" }}>
              <Box
                sx={{
                  backgroundColor: "#F9B49F",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                Placeholder
              </Box>
            </Item>{" "}
            <Item sx={{ width: "10rem", height: "3rem" }}>
              <Box
                sx={{
                  backgroundColor: "#F9B49F",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                Placeholder
              </Box>
            </Item>{" "}
            <Item sx={{ width: "10rem", height: "3rem" }}>
              <Box
                sx={{
                  backgroundColor: "#F9B49F",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                Placeholder
              </Box>
            </Item>
          </Stack> */}
        </Box>
      </Box>

      {/* ------- mobile -------- */}

      <Box
        sx={{
          width: "100%",

          display: phone ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "30vh",
          backgroundColor: " #FAF8F5 ",
          borderBottom: "1px solid #EBE9E6 ",
        }}
      >
        <h1>Our partners</h1>
        <img
          src={mobilelogos}
          alt=""
          style={{
            width: "90%",
            margin: "1rem auto",
            maxWidth: "90% !important",
          }}
        />
        {/* <Stack direction="row" spacing={2} sx={{ margin: "2rem 0" }}>
          <Item
            sx={{ flex: 1, width: "45vw", height: "5rem", margin: "2rem 0" }}
          >
            <Box
              sx={{
                backgroundColor: "#F9B49F",
                height: "4rem",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
                color: "red",
              }}
            >
              Placeholder
            </Box>
          </Item>{" "}
          <Item sx={{ flex: 1, width: "45vw", height: "5rem" }}>
            <Box
              sx={{
                backgroundColor: "#F9B49F",
                height: "4rem",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
                color: "red",
              }}
            >
              Placeholder
            </Box>
          </Item>{" "}
        </Stack> */}
      </Box>
    </>
  );
}
