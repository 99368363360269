import * as React from "react";
import { Box } from "@mui/material";

import Footer from "../../components/Footer.tsx";
import EventsHeader from "./EventsHeader.tsx";
import TrustedLocal from "../PartnerPages/NewTrustedLocal.tsx";
import NewFastConvenient from "../PartnerPages/NewFastConvenient.tsx";
import WeCanHelpEvents from "../PartnerPages/WeCanHelpEvents.tsx";
import NewTestimonials from "../Dashboard/NewTestimonials.tsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import NewIconRepeater from "../PartnerPages/NewIconRepeater.tsx";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";

interface GetStartedProps {}

const GetStarted: React.FC<GetStartedProps> = () => {
  const theme = useTheme();
  const curve = require("../../assets/helpyouneedbottomcurve.png");
  const phone = useMediaQuery(theme.breakpoints.down("sm"));

  const [utm, setUTM] = useState(
    "/?utm_campaign=social_launch_24&utm_source=facebook&utm_medium=ad&utm_content=familyvideo2&utm_id=marketing"
  );
  
  return (
    <>
      <Box
        sx={{
          // height: "fit-content",
          width: "100vw",
          backgroundColor: "white",
          maxWidth: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 ",
        }}
      >
        {/* Header */}

        <EventsHeader />
        <NewIconRepeater />
        <WeCanHelpEvents partner=""/>
        <TrustedLocal partner=""/>
        {/* <EventsTestimonials /> */}
        <NewFastConvenient />
        <img src={curve} alt="" style={{ width: "100vw", display: phone ? "none" : "flex"}} />
        <NewTestimonials />
        <Footer />
      </Box>
    </>
  );
};

export default GetStarted;
