import * as React from "react";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Navbar from "../../components/Navbar";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";

interface EventsHeaderProps {}

const EventsHeader: React.FC<EventsHeaderProps> = () => {
  const theme = useTheme();
  const [utm, setUTM] = useState(
    "/?utm_campaign=facility_launch_24&utm_source=events&utm_medium=landing_page&utm_content=cta&utm_id=website"
  );
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  // const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  const promo = require("../../assets/PromoBanner.png");
  const promoMbl = require("../../assets/PromoBannerMobile.png");
  const promoTablet = require("../../assets/EventsPromoBannerTablet.png");
  const headerimg = require("../../assets/Events/CompressedWebP/EventsLandingHero.webp");
  const mobileHeaderImg = require("../../assets/MobileAboutHeaderBg.png");
  const headerimgTablet = require("../../assets/eventsheadertablet.png");
  useEffect(() => {
    if (window.location.href.includes("?utm")) {
      setUTM(`?${window.location.href.split("?").pop()}`);
    }
  }, []);

  return (
    <>
      <Navbar color="white" />

      <Link to={`https://app.herewith.com/sign-up${utm}`}>
        <img
          src={promo}
          alt="promotional banner - get a free hour of service"
          loading="lazy"
          style={{ display: phone || tablet ? "none" : "block", width: "100%" }}
        />
      </Link>

      <Link to={`https://app.herewith.com/sign-up${utm}`}>
        <img
          src={promoTablet}
          alt="promotional banner - get a free hour of service"
          loading="lazy"
          style={{
            display: phone ? "none" : tablet ? "flex" : "none",
            width: "100%",
          }}
        />
      </Link>

      <Link to={`https://app.herewith.com/sign-up${utm}`}>
        <img
          src={promoMbl}
          alt="promotional banner - get a free hour of service"
          loading="lazy"
          style={{
            display: phone ? "block" : "none",
            borderBottom: "2px solid white",
            width: "100%",
          }}
        />
      </Link>

      <Box
        sx={{
          width: "100%",
          minHeight: phone ? "90vh" : tablet ? "fit-content" : "38rem",
          height: tablet ? "30rem" : "fit-content",
          backgroundImage: `url(${
            phone ? mobileHeaderImg : tablet ? headerimgTablet : headerimg
          })`,
          backgroundPosition: tablet ? "right" : "center ",
          backgroundRepeat: "no-repeat",
          backgroundSize: tablet ? " auto 100%" : "cover",
          paddingBottom: "2rem",
          paddingTop: tablet ? 0 : "2rem",
        }}
      >
        <Box
          sx={{
            width: phone ? "90%" : tablet ? "45%" : "38%",
            minHeight: phone ? "fit-content" : "100%",
            margin: phone ? "1rem" : tablet ? "1rem 0 0 3rem" : "0 0 0 5rem ",
            display: "flex",
            flexDirection: "column",
            alignItems: phone ? "center" : "space-between",
            justifyContent: phone ? "space-evenly" : "center",
            textAlign: phone ? "center" : "left",
          }}
        >
          <h1
            style={{
              marginBottom: phone ? "1rem" : "2rem",
              marginTop: phone ? "2rem" : 0,
              fontSize: phone ? "1.75rem" : tablet ? "2rem" : "2.5rem",
            }}
          >
            Peace of mind for you,{" "}
            <span style={{ fontStyle: "italic", color: "#795EC6" }}>
              everyday help
            </span>{" "}
            for your loved one
          </h1>
          <p
            style={{
              maxWidth: phone ? "90%" : "100%",
              fontSize: phone || tablet ? "1rem" : "1.1rem",
              lineHeight: 1.5,
              textAlign: phone ? "center" : "left",
            }}
          >
            For the times when you can’t be there, find fast, reliable help for
            your older loved one on Herewith. 
            <br /> <br />
            Set your own budget and book as much or as little help as you need.
            Get help with different tasks, from errands to companionship, tech
            support, and more from background-checked local Helpers. 
          </p>
          <Link to={`https://app.herewith.com/sign-up${utm}`}>
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
              sx={{
                width: "fit-content",
                height: "3rem",
                fontSize: "18px",
                backgroundColor: "#FDBB2E",
                color: "black",
                marginTop: "2rem",
                "&:hover": {
                  background: "#FECA5B",
                  // Add other hover styles as needed
                },
              }}
            >
              Get started today
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default EventsHeader;
