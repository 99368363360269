import * as React from "react";
import { Box } from "@mui/material";

import Footer from "../../components/Footer.tsx";
import NewPartnerHeader from "./NewPartnerHeader.tsx";
import TrustedLocal from "./NewTrustedLocal.tsx";
import NewFastConvenient from "./NewFastConvenient.tsx";
import WeCanHelpEvents from "./WeCanHelpEvents.tsx";
import NewIconRepeater from "./NewIconRepeater.tsx";
import NewTestimonials from "../Dashboard/NewTestimonials.tsx"
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import SimpleFooter from "../../components/SimpleFooter.tsx";

interface PartnerLandingProps {
  partner: string;
  showLogo: boolean;
}

const NewPartnerLanding: React.FC<PartnerLandingProps> = ({
  partner,
  showLogo,
}) => {
    const theme = useTheme();
    const curve = require("../../assets/helpyouneedbottomcurve.png");
    const phone = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Box
        sx={{
          // height: "fit-content",
          width: "100vw",
          backgroundColor: "white",
          maxWidth: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 ",
        }}
      >
        {/* Header */}

        <NewPartnerHeader showLogo={showLogo} partner={partner} />
        <NewIconRepeater/>
        <WeCanHelpEvents partner={partner} />
        <TrustedLocal partner={partner} />
        <NewFastConvenient />
        <img src={curve} alt="" style={{ width: "100vw", display: phone ? "none" : "flex"}} />
        <NewTestimonials />
        <SimpleFooter />
      </Box>
    </>
  );
};

export default NewPartnerLanding;
