import * as React from "react";
import { Box, Divider } from "@mui/material";

import Navbar from "../../components/Navbar.tsx";
import Footer from "../../components/Footer.tsx";
import Subscribe from "../../components/Subscribe.tsx";
import OurStory from "./OurStory.tsx";
import OurValues from "./OurValues.tsx";
import PhotoLeftColumn from "./PhotoLeftColumn.tsx";
import PhotoRightColumn from "./PhotoRightColumn.tsx";
import AboutTestimonials from "./AboutTestimonials.tsx";
import AboutHeader from "./AboutHeader.tsx";
import { Helmet } from "react-helmet";

interface AboutProps {}

const bullets1 = [
  "Find background-checked Helpers based on your budget & schedule, nationwide",
  "Choose one-time tasks or ongoing help for just the right amount of support",
  "Pay as you go by credit card with no commitments or subscriptions",
];

const bullets2 = [
  "Post Help Requests for the things you need, including Tech Help, Home Help, Errands, Companionship, and more",
  "Get support that comes to your residence—assisted living community, rehabilitation center, or family home",
  "Stay protected with liability insurance when Helpers come over",
];

const bullets3 = [
  "Get paid to help seniors with simple, everyday tasks",
  "Work when you want and book tasks directly with clients on the Herewith platform",
  "Never pay a penny to Herewith—get free background checks, free liability insurance on the job, and an easy mobile app to job search and get paid",
];

const bullets4 = [
  "Provide a competitive benefit for the rising number of employees who care for older loved ones",
  "Increase employees’ peace of mind, health, and productivity, knowing they have easy access to senior care",
  "Offer easy, real-time solutions for employees, reducing costly absences and turnover for businesses",
];

const About: React.FC<AboutProps> = () => {
  const img1 = require("../../assets/About/CompressedWebP/image1.webp");
  const img2 = require("../../assets/About/CompressedWebP/about2.webp");
  const img3 = require("../../assets/About/CompressedWebP/about3.webp");
  const img4 = require("../../assets/About/CompressedWebP/about4.webp");

  const mobileImg1 = require("../../assets/About/CompressedWebP/MobileAbt1.webp");
  const mobileImg2 = require("../../assets/About/CompressedWebP/MobileAbt2.webp");
  const mobileImg3 = require("../../assets/About/CompressedWebP/MobileAbt3.webp");
  const mobileImg4 = require("../../assets/MobileAbt4.png");

  const ban = require("../../assets/partnerbanner.png");
  const logo = require("../../assets/Logo.png");

  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          content="Learn how we’re changing the conversation on senior care, connecting families and older adults with everyday help through our easy-to-use online platform."
        />
      </Helmet>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",

          maxWidth: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 ",
        }}
      >
        {/* Header */}

        <AboutHeader />

        {/* Components */}

        <PhotoLeftColumn
          img={img1}
          title1="For families"
          title2="Fast, flexible help"
          bullets={bullets1}
          cta="Find help for your family"
          subColor="#1D99BE"
          mobileImg={mobileImg1}
          link="https://app.herewith.com/sign-up/?utm_campaign=facility_launch_24&utm_source=about&utm_medium=page&utm_content=cta_family&utm_id=website"
        />
        <Divider
          sx={{
            opacity: "50%",
            width: "100%",
          }}
        />
        <PhotoRightColumn
          img={img2}
          title1="For older adults"
          title2="Independence"
          bullets={bullets2}
          cta="Find help for yourself"
          subColor="#6AA08F"
          mobileImg={mobileImg2}
          link="https://app.herewith.com/sign-up/?utm_campaign=facility_launch_24&utm_source=about&utm_medium=page&utm_content=cta_family&utm_id=website"
        />
        <Divider
          sx={{
            opacity: "50%",
            width: "100%",
          }}
        />
        <PhotoLeftColumn
          img={img3}
          title1="For Helpers"
          title2="Rewarding work"
          bullets={bullets3}
          cta="Become a Helper"
          subColor="#C97B99"
          mobileImg={mobileImg3}
          link="https://app.herewith.com/pro/sign-up/?utm_campaign=facility_launch_24&utm_source=about&utm_medium=page&utm_content=cta_helper&utm_id=website"
        />
        <Divider
          sx={{
            opacity: "50%",
            width: "100%",
          }}
        />
        <PhotoRightColumn
          img={img4}
          title1="For Partners"
          title2="Benefits worth sharing"
          bullets={bullets4}
          cta="Get in touch"
          subColor="#947ED1"
          mobileImg={img4}
          link="/partners#getintouch"
        />

        <OurStory />
        <OurValues />
        <AboutTestimonials />
        <Subscribe />
        <Footer />
      </Box>
    </>
  );
};

export default About;
