import * as React from "react";
import Box from "@mui/material/Box";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
interface CgTestimonialsProps {}

const CgTestimonials: React.FC<CgTestimonialsProps> = () => {
  const top = require("../../assets/cgtestimonialscurve.png");
  const mobile = require("../../assets/MobileTrackHelpBg.png");

  const first = require("../../assets/Home/CompressedWebP/testimonials1.webp");
  const second = require("../../assets/Home/CompressedWebP/testimonials2.webp");
  const third = require("../../assets/Home/CompressedWebP/testimonials3.webp");
  const fourth = require("../../assets/Home/CompressedWebP/testimonials4.webp");
  const fifth = require("../../assets/Home/CompressedWebP/testimonials5.webp");
  const sixth = require("../../assets/Home/CompressedWebP/testimonials6.webp");

  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <>
      <img src={top} style={{ margin: "0", width: "100%" }} loading="lazy" />

      <Box
        sx={{
          width: "100%",
          minHeight: phone || tablet ? "fit-content" : "30rem",
          height: "fit-content",
          display: "block",
          margin: phone ? "0 1rem" : " 0",
          padding: phone ? "1rem" : "2rem",
          backgroundColor: "white",
        }}
      >
        <h1 style={{ display: phone ? "block" : "none" }}>
          What people <br />
          are saying
        </h1>
        <h1
          style={{
            display: phone ? "none" : "block",
            marginBottom: "2rem",
            fontSize: tablet ? "2rem" : "2.5rem",
          }}
        >
          What people are saying
        </h1>
        <Box
          sx={{
            width: phone ? "100%" : tablet ? "100%" : "50%",
            margin: "0 auto",
          }}
        >
          <Carousel
            autoPlay
            showThumbs={false}
            showIndicators={true}
            showStatus={false}
          >
            <div style={{ minHeight: phone ? "8rem" : 0 }}>
              <img
                src={first}
                style={{
                  margin: "1rem 0 2rem 0",
                  borderRadius: "1rem",
                  height: phone || tablet ? "8rem" : "10rem",
                  width: "auto",
                }}
              />
              <Divider sx={{ opacity: "50%", marginBottom: "1rem" }} />

              <p
                style={{
                  fontSize: "1.1rem",
                  lineHeight: " 1.5",
                  marginBottom: phone ? "1rem" : "2.5rem",
                }}
              >
                “I like that Herewith’s a task-oriented gig. The pay is good,
                the requirements are low, and it gives me the opportunity to
                help out seniors.” <br /> - Sydney
              </p>
            </div>
            <div style={{ minHeight: phone ? "8rem" : 0 }}>
              <img
                src={second}
                style={{
                  margin: "1rem 0 2rem 0",
                  borderRadius: "1rem",
                  height: phone || tablet ? "8rem" : "10rem",
                  width: "auto",
                }}
              />
              <Divider sx={{ opacity: "50%", marginBottom: "1rem" }} />

              <p
                style={{
                  fontSize: "1.1rem",
                  lineHeight: " 1.5",
                  marginBottom: phone ? "1rem" : "2.5rem",
                }}
              >
                “I've used other sites. I think Herewith was a little more
                motivated to get me a client. It's kind of just effortless. You
                guys do a lot of the work—makes my life a little bit easier.”{" "}
                <br />   – Stephanie
              </p>
            </div>
            <div style={{ minHeight: phone ? "8rem" : 0 }}>
              <img
                src={third}
                style={{
                  margin: "1rem 0 2rem 0",
                  borderRadius: "1rem",
                  height: phone || tablet ? "8rem" : "10rem",
                  width: "auto",
                }}
              />
              <Divider sx={{ opacity: "50%", marginBottom: "1rem" }} />

              <p
                style={{
                  fontSize: "1.1rem",
                  lineHeight: " 1.5",
                  marginBottom: phone ? "1rem" : "2.5rem",
                }}
              >
                “Herewith really wants the best caregiver for the person who
                needs care.” <br /> – Carol
              </p>
            </div>
            <div style={{ minHeight: phone ? "8rem" : 0 }}>
              <img
                src={fourth}
                style={{
                  margin: "1rem 0 2rem 0",
                  borderRadius: "1rem",
                  height: phone || tablet ? "8rem" : "10rem",
                  width: "auto",
                }}
              />
              <Divider sx={{ opacity: "50%", marginBottom: "1rem" }} />

              <p
                style={{
                  fontSize: "1.1rem",
                  lineHeight: " 1.5",
                  marginBottom: phone ? "1rem" : "2.5rem",
                }}
              >
                “I really feel Herewith is on my side, being considerate and
                thoughtful. Everybody I have dealt with has been absolutely
                wonderful and helpful. <br /> – Marie
              </p>
            </div>
            <div style={{ minHeight: phone ? "8rem" : 0 }}>
              <img
                src={fifth}
                style={{
                  margin: "1rem 0 2rem 0",
                  borderRadius: "1rem",
                  height: phone || tablet ? "8rem" : "10rem",
                  width: "auto",
                }}
              />
              <Divider sx={{ opacity: "50%", marginBottom: "1rem" }} />

              <p
                style={{
                  fontSize: "1.1rem",
                  lineHeight: " 1.5",
                  marginBottom: phone ? "1rem" : "2.5rem",
                }}
              >
                “I think Herewith does a good job to make sure that the
                caregivers are alright. If we don't understand something, then
                they reach out to you. Go step by step with you to make sure
                that you understand.” <br /> – Emma
              </p>
            </div>
            <div style={{ minHeight: phone ? "8rem" : "fit-content" }}>
              <img
                src={sixth}
                style={{
                  margin: "1rem 0 2rem 0",
                  borderRadius: "1rem",
                  height: phone || tablet ? "8rem" : "10rem",
                  width: "auto",
                }}
              />
              <Divider sx={{ opacity: "50%", marginBottom: "1rem" }} />

              <p
                style={{
                  fontSize: "1.1rem",
                  lineHeight: " 1.5",
                  marginBottom: phone ? "1rem" : "2.5rem",
                }}
              >
                “When I needed help, they sent me a little tutorial. And I was
                like, okay, piece of cake and I figured it out.”
                <br />   – Sandra
              </p>
            </div>
          </Carousel>
        </Box>
      </Box>
    </>
  );
};

export default CgTestimonials;
