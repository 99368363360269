import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import {
  Box,
  InputBase,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Divider,
  Button,
  Radio,
} from "@mui/material";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled, alpha } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: alpha(theme.palette.common.white, 1),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.95),
  },
  marginLeft: 0,
  width: "40% !important",
  height: "4rem",
  borderRadius: "9999px",
  display: "flex",
  padding: "8px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
  [theme.breakpoints.down("md")]: {
    width: "100% !important",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100% !important",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "80%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "3rem",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  flex: 1,
  height: "100%",
  borderRadius: "9999px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1),
    height: "100%",
    fontSize: "1rem",
    paddingLeft: `calc(1em + ${theme.spacing(1)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("md")]: {
      paddingLeft: "0 !important",
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
}));

export default function Subscribe() {
  const bg = require("../assets/Home/CompressedWebP/subscribeImg.webp");
  const mobileBg = require("../assets/Home/CompressedWebP/MobileSubscribeBg.webp");
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  const [open, setOpen] = React.useState(false);

  const [values, setValues] = useState({
    message: "I am looking for some help",
    user_email: "",
  });
  const [showform, setShowForm] = useState(true);
  const { message, user_email } = values;
  const handleChange = (name: any) => (e: any) => {
    setValues({ ...values, [name]: e.target.value });
  };
  console.log(values);
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // service_id, templte_id and public key will get from Emailjs website when you create account and add template service and email service
    console.table({
      message,
      user_email,
    });
    emailjs
      .send("service_0wpnajk", "template_sjxk14h", values, "6RrTocq4cQC5EE_MT")
      .then(
        (result) => {
          console.log(result);
          setShowForm(false);
          setValues({
            message: "",
            user_email: "",
          });
          setOpen(true);
        },
        (error) => {
          console.log(error);
        }
      );
  };
  return (
    <>
      {/* ---------------- desktop ------------- */}
      <Box
        sx={{
          width: "100%",
          overflow: "hidden",
          display: phone ? "none" : tablet ? "none" : "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "25rem",
          backgroundColor: "white",
          borderTop: "1px solid #EBE9E6",
          borderBottom: "1px solid #EBE9E6",
        }}
      >
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message="Subsription request submitted!"
          action={action}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        />
        <Box
          sx={{
            py: 0,
            width: "90%",
            backgroundColor: " #DBF7FF",
            borderRadius: "2rem",
            fontSize: "1.1rem",
            height: "70%",
            color: "#0C3D4C",
            margin: "3rem auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* Left column */}

          <Box
            sx={{
              width: "75%",
              height: "80%",
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "space-between",
              padding: "1rem 2rem 1rem 3rem",
            }}
          >
            <Box
              sx={{
                height: "45%",
                lineHeight: "1.5",
              }}
            >
              <h3>Stay in the know</h3>
              <p
                style={{ fontWeight: "lighter !important", fontSize: "1.2rem" }}
              >
                Get the latest Herewith offers, updates, and tips
              </p>
            </Box>

            <Divider
              sx={{ backgroundColor: "#ACE0EF", border: "1px solid #ACE0EF" }}
            />
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "45%",
                  marginTop: "1rem",
                }}
              >
                <Box
                  sx={{
                    height: "100%",

                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <FormControl sx={{ fontSize: "1.2rem" }}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="message"
                      onChange={handleChange("message")}
                      defaultValue="I am looking for some help"
                      sx={{
                        fontSize: "1.2rem",
                      }}
                    >
                      <FormControlLabel
                        value="I am looking for some help"
                        control={<Radio size="medium" />}
                        sx={{ marginLeft: 0 }}
                        label={
                          <p style={{ fontSize: "1.2rem" }}>
                            I'm looking for home help
                          </p>
                        }
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value="I'm looking for jobs"
                        labelPlacement="start"
                        control={<Radio size="medium" />}
                        label={
                          <p style={{ fontSize: "1.2rem" }}>
                            I'm looking for jobs
                          </p>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Search>
                  <StyledInputBase
                    onChange={handleChange("user_email")}
                    placeholder="Enter your email "
                    inputProps={{ "aria-label": "search" }}
                    name="user_email"
                    value={values.user_email}
                  />
                  <Button
                    variant="contained"
                    disableElevation
                    type="submit"
                    sx={{
                      borderRadius: "9999px",
                      fontSize: "1rem",
                      background: "#E8E9E6",

                      padding: "0 1rem",
                      "&:hover": {
                        background: "#E8E9E6",
                        // Add other hover styles as needed
                      },
                    }}
                  >
                    Submit
                  </Button>
                </Search>
              </Box>
            </form>
          </Box>
          <Box sx={{ height: "100%" }}>
            {" "}
            <img
              src={bg}
              alt="bg"
              style={{ height: "100%", position: "relative", bottom: 0 }}
            />
          </Box>
        </Box>
      </Box>

      {/* ---------------- tablet ------------- */}
      <Box
        sx={{
          width: "100%",
          overflow: "hidden",
          display: phone ? "none" : tablet ? "flex" : "none",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "25rem",
          minHeight: "fit-content",
          backgroundColor: "white",
          borderTop: "1px solid #EBE9E6",
          borderBottom: "1px solid #EBE9E6",
        }}
      >
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message="Subsription request submitted!"
          action={action}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        />
        <Box
          sx={{
            py: 0,
            width: "90%",
            backgroundColor: " #DBF7FF",
            borderRadius: "2rem",
            fontSize: "1rem",
            height: "80%",
            color: "#0C3D4C",
            margin: "3rem auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* Left column */}

          <Box
            sx={{
              width: "65%",
              height: "90%",
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "space-between",
              padding: "1rem 1.5rem",
            }}
          >
            <Box
              sx={{
                height: "45%",
                lineHeight: "1.5",
              }}
            >
              <h3>Stay in the know</h3>
              <p
                style={{
                  fontWeight: "lighter !important",
                  fontSize: "1.2rem",
                  paddingBottom: "1rem",
                }}
              >
                Get the latest Herewith offers, updates, and tips
              </p>
            </Box>

            <Divider
              sx={{ backgroundColor: "#ACE0EF", border: "1px solid #ACE0EF" }}
            />
            <form
              style={{ display: "flex", alignItems: "flex-start", padding: 0 }}
              onSubmit={handleSubmit}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  height: "45%",

                  marginTop: "1rem",
                }}
              >
                <Box
                  sx={{
                    width: "100%",

                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <FormControl sx={{ fontSize: "1rem" }}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="message"
                      onChange={handleChange("message")}
                      defaultValue="I am looking for some help"
                      sx={{
                        fontSize: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        margin: "0 0 1rem 0",
                      }}
                    >
                      <FormControlLabel
                        value="I am looking for some help"
                        control={<Radio size="medium" />}
                        sx={{ marginLeft: 0 }}
                        label={
                          <p style={{ fontSize: "1rem" }}>
                            I'm looking for home help
                          </p>
                        }
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        sx={{ marginLeft: 0 }}
                        value="I'm looking for jobs"
                        labelPlacement="start"
                        control={<Radio size="medium" />}
                        label={
                          <p style={{ fontSize: "1rem" }}>
                            I'm looking for jobs
                          </p>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Search>
                  <StyledInputBase
                    onChange={handleChange("user_email")}
                    placeholder="Enter your email "
                    inputProps={{ "aria-label": "search" }}
                    name="user_email"
                    value={values.user_email}
                  />
                  <Button
                    variant="contained"
                    disableElevation
                    type="submit"
                    sx={{
                      borderRadius: "9999px",
                      fontSize: "1rem",
                      background: "#E8E9E6",

                      padding: "0 1rem",
                      "&:hover": {
                        background: "#E8E9E6",
                        // Add other hover styles as needed
                      },
                    }}
                  >
                    Submit
                  </Button>
                </Search>
              </Box>
            </form>
          </Box>
          <Box
            sx={{
              height: "100%",
              width: "35%",
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            {" "}
            <img
              src={bg}
              alt="bg"
              style={{
                width: "120%",
                position: "relative",
                bottom: 0,
              }}
            />
          </Box>
        </Box>
      </Box>

      {/* ---------------- mobile ------------- */}

      <Box
        sx={{
          height: "fit-content",
          minHeight: "48rem",
          width: "100vw",
          display: phone ? "block" : "none",
        }}
      >
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message="Subsription request submitted!"
          action={action}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            //  justifyContent: "space-between",
            alignItems: "center",
            backgroundImage: `url(${mobileBg})`,
            margin: "2rem auto",
            width: "90%",
            height: "fit-content",
            minHeight: "48rem",
            backgroundPosition: "center top",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            borderRadius: "2.5rem",
            position: "relative",
          }}
        >
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Box
              sx={{
                height: "65%",
                lineHeight: "1.5",
                position: "absolute",
                bottom: 0,
                textAlign: "left",
                padding: "1rem",
              }}
            >
              {/* Heading */}

              {/* <h3>Stay in the know</h3>
            <h3>Get the latest Herewith offers, updates, and tips</h3>
            <Box
              sx={{
                height: "45%",
                lineHeight: "1.5",
              }}
            > */}
              <h3>Stay in the know</h3>
              <p
                style={{ fontWeight: "lighter !important", fontSize: "1.2rem" }}
              >
                Get the latest Herewith offers, updates, and tips
              </p>
              {/* </Box> */}
              <Divider
                sx={{
                  backgroundColor: "#ACE0EF",
                  border: "2px solid #ACE0EF",
                  margin: "1.25rem auto",
                }}
              />

              {/* radio group */}

              <FormControl sx={{ fontSize: "1.2rem", marginBottom: "1rem" }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="message"
                  onChange={handleChange("message")}
                  defaultValue="I am looking for some help"
                  sx={{ fontSize: "1.2rem" }}
                >
                  <FormControlLabel
                    value="I am looking for some help"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 30,
                          },
                        }}
                      />
                    }
                    label={
                      <p style={{ fontSize: "1.2rem" }}>
                        I'm looking for home help
                      </p>
                    }
                    labelPlacement="start"
                    sx={{
                      width: "100%",
                      margin: 0,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  />
                  <FormControlLabel
                    value="I'm looking for jobs"
                    labelPlacement="start"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 30,
                          },
                        }}
                      />
                    }
                    label={
                      <p style={{ fontSize: "1.2rem" }}>I'm looking for jobs</p>
                    }
                    sx={{
                      width: "100%",
                      margin: ".5rem 0",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  />
                </RadioGroup>
              </FormControl>

              {/* input and submit */}

              <Search>
                <StyledInputBase
                  onChange={handleChange("user_email")}
                  placeholder="Enter your email "
                  inputProps={{ "aria-label": "search" }}
                  name="user_email"
                  value={values.user_email}
                />
              </Search>

              <Button
                variant="contained"
                type="submit"
                disableElevation
                sx={{
                  borderRadius: "9999px",
                  fontSize: "1rem",
                  background: "#E8E9E6",
                  padding: "0 1rem",
                  height: "4rem",
                  width: "100%",
                  display: "flex",
                  marginTop: "1rem",
                  "&:hover": {
                    background: "#E8E9E6",
                    // Add other hover styles as needed
                  },
                }}
              >
                Submit
              </Button>
            </Box>
          </form>

          <Divider
            sx={{ backgroundColor: "#ACE0EF", border: "1px solid #ACE0EF" }}
          />
        </Box>
      </Box>
    </>
  );
}

// hello@herewith.com
