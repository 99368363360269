import * as React from "react";
import Box from "@mui/material/Box";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
interface TestimonialsProps {}

const Testimonials: React.FC<TestimonialsProps> = () => {
  const first = require("../../assets/Home/CompressedWebP/testimonials1.webp");
  const second = require("../../assets/Home/CompressedWebP/testimonials2.webp");
  const third = require("../../assets/Home/CompressedWebP/testimonials3.webp");
  const fourth = require("../../assets/Home/CompressedWebP/testimonials4.webp");
  const fifth = require("../../assets/Home/CompressedWebP/testimonials5.webp");
  const sixth = require("../../assets/Home/CompressedWebP/testimonials6.webp");

  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  const mobile = require("../../assets/Home/CompressedWebP/MobileTrackHelpBg.webp");
  return (
    <>
      <Box
        sx={{
          width: "100%",
          minHeight: phone ? "fit-content" : "30rem",
          height: "fit-content",
          display: "block",
          margin: phone ? "1rem" : " 0",
          padding: phone ? "1rem" : "2rem",
          backgroundColor: "white",
        }}
      >
        <h1 style={{ display: phone ? "block" : "none" }}>
          What people <br />
          are saying
        </h1>
        <h1
          style={{
            display: phone ? "none" : "block",
            marginBottom: "2rem",
            fontSize: tablet ? "2rem" : "2.5rem",
          }}
        >
          What people are saying
        </h1>
        <Box
          sx={{
            width: phone ? "100%" : tablet ? "100%" : "50%",
            margin: "0 auto",
          }}
        >
          <Carousel
            autoPlay
            showThumbs={false}
            showIndicators={true}
            showStatus={false}
          >
            <div style={{ minHeight: phone ? "8rem" : 0 }}>
              <img
                src={first}
                loading="lazy" // Lazy load the images
                alt={`Young lady giving handshake to person offscreen`}
                style={{
                  margin: "1rem 0 2rem 0",
                  borderRadius: "1rem",
                  height: phone || tablet ? "8rem" : "10rem",
                  width: "auto",
                }}
              />
              <Divider sx={{ opacity: "50%", marginBottom: "1rem" }} />

              <p
                style={{
                  fontSize: "1.1rem",
                  lineHeight: " 1.5",
                  marginBottom: phone ? "1rem" : "2.5rem",
                }}
              >
                “I like being able to filter caregivers by what you need. I need
                somebody with their own reliable transportation or I need
                somebody that speaks a different language.” <br /> - Mary
              </p>
            </div>
            <div style={{ minHeight: phone ? "8rem" : 0 }}>
              <img
                src={second}
                loading="lazy" // Lazy load the images
                alt={`Two elderly women. One is fixing the others hair.`}
                style={{
                  margin: "1rem 0 2rem 0",
                  borderRadius: "1rem",
                  height: phone || tablet ? "8rem" : "10rem",
                  width: "auto",
                }}
              />
              <Divider sx={{ opacity: "50%", marginBottom: "1rem" }} />

              <p
                style={{
                  fontSize: "1.1rem",
                  lineHeight: " 1.5",
                  marginBottom: phone ? "1rem" : "2.5rem",
                }}
              >
                “I just want to say a big thank you to Herewith. It’s been
                phenomenal for me. I even have a friend who wants to use it to
                take care of her mom.” <br /> - Pauline
              </p>
            </div>
            <div style={{ minHeight: phone ? "8rem" : 0 }}>
              <img
                src={third}
                loading="lazy" // Lazy load the images
                alt={`Younger woman hugging older woman in a city downtown.`}
                style={{
                  margin: "1rem 0 2rem 0",
                  borderRadius: "1rem",
                  height: phone || tablet ? "8rem" : "10rem",
                  width: "auto",
                }}
              />
              <Divider sx={{ opacity: "50%", marginBottom: "1rem" }} />

              <p
                style={{
                  fontSize: "1.1rem",
                  lineHeight: " 1.5",
                  marginBottom: phone ? "1rem" : "2.5rem",
                }}
              >
                “Having that freedom of knowing my mom is in good hands with her
                caregiver is everything. Herewith gave that to me.” <br /> -
                Suzy
              </p>
            </div>
            <div style={{ minHeight: phone ? "8rem" : 0 }}>
              <img
                src={fourth}
                loading="lazy" // Lazy load the images
                alt={`Younger woman speaking to older woman in the park.`}
                style={{
                  margin: "1rem 0 2rem 0",
                  borderRadius: "1rem",
                  height: phone || tablet ? "8rem" : "10rem",
                  width: "auto",
                }}
              />
              <Divider sx={{ opacity: "50%", marginBottom: "1rem" }} />

              <p
                style={{
                  fontSize: "1.1rem",
                  lineHeight: " 1.5",
                  marginBottom: phone ? "1rem" : "2.5rem",
                }}
              >
                “Through Herewith I found wonderful care for my hundred year old
                Italian mother... She’s always on time, upbeat, and as a CNA, a
                very capable and compassionate caregiver.” <br /> - Dolores
              </p>
            </div>
            <div style={{ minHeight: phone ? "8rem" : 0 }}>
              <img
                src={fifth}
                loading="lazy" // Lazy load the images
                alt={`Young woman assisting older man with reading.`}
                style={{
                  margin: "1rem 0 2rem 0",
                  borderRadius: "1rem",
                  height: phone || tablet ? "8rem" : "10rem",
                  width: "auto",
                }}
              />
              <Divider sx={{ opacity: "50%", marginBottom: "1rem" }} />

              <p
                style={{
                  fontSize: "1.1rem",
                  lineHeight: " 1.5",
                  marginBottom: phone ? "1rem" : "2.5rem",
                }}
              >
                “I appreciate that I can call Support when I have a question.
                It’s important for me to be able to reach people beyond the app
                when needed. Good customer service.” <br /> - George
              </p>
            </div>
            <div style={{ minHeight: phone ? "8rem" : "fit-content" }}>
              <img
                src={sixth}
                loading="lazy" // Lazy load the images
                alt={`Two women, one youger, one older reading a book together.`}
                style={{
                  margin: "1rem 0 2rem 0",
                  borderRadius: "1rem",
                  height: phone || tablet ? "8rem" : "10rem",
                  width: "auto",
                }}
              />
              <Divider sx={{ opacity: "50%", marginBottom: "1rem" }} />

              <p
                style={{
                  fontSize: "1.1rem",
                  lineHeight: " 1.5",
                  marginBottom: phone ? "1rem" : "2.5rem",
                }}
              >
                “I have found the Herewith app to be quite user-friendly, even
                for someone like me who is not at all tech savvy. It feels good
                to have their support. I highly recommend their service. If I
                had stars to light up, there would be five of them.” <br /> -
                Diane
              </p>
            </div>
          </Carousel>
        </Box>
      </Box>
    </>
  );
};

export default Testimonials;
