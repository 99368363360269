import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
// import logo from "../assets/Logo.png";
import FooterLink from "../../components/FooterLink.tsx";
import FooterHeader from "../../components/FooterHeader.tsx";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";

import LockIcon from "@mui/icons-material/Lock";
import FacebookIcon from "../../assets/icons/FacebookIcon.tsx";
import LinkedinIcon from "../../assets/icons/LinkedinIcon.tsx";
import InstaIcon from "../../assets/icons/InstaIcon.tsx";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function HelperFooter() {
  const logo = require("../../assets/FooterLogo.png");

  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Box
      sx={{
        width: "100%",
        // position: "absolute",
        // bottom: 0,
        display: "block",
        background: "white",
        boxSizing: "border-box",
        height: "40rem",
        minHeight: "fit-content",

        padding: phone ? "1rem" : tablet ? "2.5rem" : "3rem 5rem",
      }}
    >
      <AppBar
        elevation={0}
        position="static"
        sx={{
          background: "white",

          bottom: 0,
          display: "flex",
          flexDirection: phone ? "column" : tablet ? "column" : "row",
          height: "fit-content",
          width: "100%",

          alignItems: "flex-start",
          justifyContent: "space-between",
          padding: "1rem ",
          margin: 0,
        }}
      >
        <Box sx={{ width: phone ? "100%" : "50%", textAlign: "left" }}>
          <Link
            style={{
              textDecoration: "none",
            }}
            to="/"
          >
            <img
              src={logo}
              style={{
                width: phone ? "50%" : tablet ? "45%" : "30%",
                marginBottom: tablet ? "3rem" : "none",
              }}
              alt="logo"
            />
          </Link>
        </Box>
      </AppBar>
      <Divider variant="fullWidth" sx={{ opacity: "0.5" }} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: phone ? "column-reverse" : "row",
          justifyContent: phone ? "flex-start" : "space-between",
          alignItems: phone ? "flex-start" : "center",
          padding: phone ? "1rem" : "1rem 0",
        }}
      >
        {" "}
        <p style={{ fontSize: "1.25rem", marginTop: phone ? "2rem" : 0 }}>
          Copyright 2024
        </p>
        <Stack direction="row" spacing={phone ? 4 : 1}>
          <Link to="https://www.facebook.com/herewithdotcom/">
            {" "}
            <IconButton aria-label="Herewith facebook">
              <FacebookIcon />
            </IconButton>
          </Link>

          <Link to="https://www.instagram.com/_herewith/">
            <IconButton aria-label="delete" disabled color="primary">
              <InstaIcon />
            </IconButton>
          </Link>

          <Link to="https://www.linkedin.com/company/herewith">
            <IconButton color="secondary" aria-label="add an alarm">
              <LinkedinIcon />
            </IconButton>
          </Link>
        </Stack>
      </Box>
      <Divider variant="fullWidth" sx={{ opacity: "0.5" }} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          fontSize: "1rem",
          textAlign: "left",
          alignItems: "center",
          padding: phone ? "2rem 1rem" : "2rem 0",
          lineHeight: 1.5,
        }}
      >
        <p>
          Herewith provides a platform to assist Helpers and care recipients in
          connecting regarding in-home non-medical care opportunities. Herewith
          does not employ any Helpers, nor does it recommend any Helpers and/or
          care recipients who use its platform. User information provided in
          profiles, posts, and otherwise on the Herewith platform is not
          generated or verified by Herewith. Each user of Herewith’s platform is
          responsible for conducting their own vetting before determining
          whether to enter into an employment relationship and for their own
          conduct, including compliance with applicable laws.
        </p>
      </Box>
      <Divider variant="fullWidth" sx={{ opacity: "0.5" }} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: phone ? "column" : "row",
          fontSize: "1rem",
          textAlign: "left",
          alignItems: phone ? "flex-start" : "center",
          padding: "1rem 0",
        }}
      >
        <Chip
          icon={<LockIcon />}
          label="SSL"
          size="medium"
          sx={{ margin: phone ? "1rem" : "0 1rem 0 0", marginRight: "1rem" }}
        />
        <Chip
          icon={<LockIcon />}
          label="CCPA Compliant"
          size="medium"
          sx={{ margin: phone ? "1rem" : "0 1rem 0 0", marginRight: "1rem" }}
        />
        <Divider orientation="vertical" flexItem />
        <p style={{ margin: "0 1rem", width: phone ? "100%" : "30%" }}>
          Herewith takes your privacy seriously. Please review our privacy
          policy to understand how we use your data.
        </p>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ display: phone ? "none" : "block" }}
        />

        <p
          style={{
            margin: phone ? "1rem 0" : "0 1rem",
            width: phone ? "100%" : "30%",
            // borderTop: "1px solid lightgray",
            padding: phone ? "1rem" : 0,
          }}
        >
          Secure Payments with{" "}
          <span style={{ fontWeight: "bold", color: "purple" }}>Stripe</span>
        </p>
      </Box>
    </Box>
  );
}
