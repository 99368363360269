import * as React from "react";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Navbar from "../../components/Navbar";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";

interface EventsHeaderProps {}

const EventsHeader: React.FC<EventsHeaderProps> = () => {
  const theme = useTheme();
  const [utm, setUTM] = useState(
    "/?utm_campaign=social_launch_24&utm_source=facebook&utm_medium=ad&utm_content=familyvideo2&utm_id=marketing"
  );
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  // const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  const promo = require("../../assets/Partners/CompressedWebP/BannerDesktop.webp");
  const promoMbl = require("../../assets/Partners/CompressedWebP/BannerMobile.webp");
  const promoTablet = require("../../assets/Partners/CompressedWebP/BannerTablet.webp");
  const headerimg = require("../../assets/Events/CompressedWebP/EventsLandingHero.webp");
  const mobileHeaderImg = require("../../assets/Partners/CompressedWebP/PartnerHeroMobile.webp");
  const headerimgTablet = require("../../assets/Partners/CompressedWebP/PartnerHeroTablet.webp");

  useEffect(() => {
    if (window.location.href.includes("?utm")) {
      setUTM(`?${window.location.href.split("?").pop()}`);
    }
  }, []);
  return (
    <>
      <Navbar color="white" />

      <Link to={`https://app.herewith.com/sign-up${utm}`}>
        <img
          src={promo}
          alt="promotional banner - get a free hour of service"
          loading="lazy"
          style={{ display: phone || tablet ? "none" : "block", width: "100%" }}
        />
      </Link>

      <Link to={`https://app.herewith.com/sign-up${utm}`}>
        <img
          src={promoTablet}
          alt="promotional banner - get a free hour of service"
          loading="lazy"
          style={{
            display: phone ? "none" : tablet ? "flex" : "none",
            width: "100%",
          }}
        />
      </Link>

      <Link to={`https://app.herewith.com/sign-up${utm}`}>
        <img
          src={promoMbl}
          alt="promotional banner - get a free hour of service"
          loading="lazy"
          style={{
            display: phone ? "block" : "none",
            borderBottom: "2px solid white",
            width: "100%",
          }}
        />
      </Link>

      <Box
        sx={{
          width: "100%",
          minHeight: phone ? "90vh" : tablet ? "fit-content" : "38rem",
          height: tablet ? "30rem" : "fit-content",
          backgroundImage: `url(${
            phone ? mobileHeaderImg : tablet ? headerimgTablet : headerimg
          })`,
          backgroundPosition: tablet ? "right" : "center ",
          backgroundRepeat: "no-repeat",
          backgroundSize: tablet ? " auto 100%" : "cover",
          paddingBottom: "2rem",
          paddingTop: tablet ? 0 : "2rem",
        }}
      >
        <Box
          sx={{
            width: phone ? "90%" : tablet ? "45%" : "38%",
            minHeight: phone ? "fit-content" : "100%",
            margin: phone ? "1rem" : tablet ? "1rem 0 0 3rem" : "0 0 0 5rem ",
            display: "flex",
            flexDirection: "column",
            alignItems: phone ? "center" : "space-between",
            justifyContent: phone ? "space-evenly" : "center",
            textAlign: phone ? "center" : "left",
          }}
        >
          <h1
            style={{
              marginBottom: phone ? "1rem" : "2rem",
              marginTop: phone ? "2rem" : 0,
              fontSize: phone ? "28px" : tablet ? "28px" : "40px",
              display: "block"
            }}
          >
            <span style={{ fontStyle: "italic", color: "#795EC6", display: "block"}}>
              Need a helping hand?
            </span>{" "}
            Try task-based help for seniors
          </h1>
          <p
            style={{
              maxWidth: phone || tablet ? "90%" : "100%",
              fontSize: phone || tablet ? "16px" : "16px",
              lineHeight: 1.5,
              textAlign: phone ? "center" : "left",
            }}
          >
            On Herewith you’ll set your own budget, choose your own Helper, 
            and book as much or as little help as you need, with everything 
            from tasks around the house to outings, errands, and even basic tech help.
          </p>
          <Link to={`https://app.herewith.com/sign-up${utm}`}>
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
              sx={{
                width: "fit-content",
                height: "3rem",
                fontSize: "18px",
                backgroundColor: "#FDBB2E",
                color: "black",
                marginTop: "2rem",
                boxShadow: "none !important", // Remove box shadow
                transition: "background 0.5s ease", // Smooth transition for background color
                "&:hover": {
                    background: "#E59101",
                    "& #buttontext": {
                        paddingRight: "5px", // Shift text on hover
                    },
                    },
                    "& #buttontext": {
                    transition: "padding-right 0.5s ease", // Smooth return when hover ends
                },
            }}
            >
              <span id="buttontext">Claim your welcome offer</span>
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default EventsHeader;
