import SvgIcon from "@mui/material/SvgIcon";

export default function SubscriptionIcon() {
  return (
    <SvgIcon
      sx={{
        width: "inherit",
        flex: 1,
        height: "50%",
      }}
    >
      <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="24" fill="#C9BFE8" />
        <circle opacity="0.5" cx="32.5" cy="31" r="4.8" fill="#493877" />
        <path
          opacity="0.5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.2692 13C16.7398 13 15.5 14.2398 15.5 15.7692V32.3846C15.5 33.914 16.7398 35.1538 18.2692 35.1538H28.1704C27.1358 34.0758 26.5 32.6121 26.5 31C26.5 27.8155 28.9809 25.2104 32.1154 25.0121V19.6855C32.1154 18.9511 31.8236 18.2467 31.3043 17.7274L27.388 13.8111C26.8687 13.2918 26.1643 13 25.4299 13H18.2692Z"
          fill="#493877"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.5 31C29.5 30.6687 29.7686 30.4 30.1 30.4H34.9C35.2314 30.4 35.5 30.6687 35.5 31C35.5 31.3314 35.2314 31.6 34.9 31.6H30.1C29.7686 31.6 29.5 31.3314 29.5 31Z"
          fill="#493877"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.1924 20.385C19.1924 19.8752 19.6057 19.4619 20.1155 19.4619H27.5001C28.0099 19.4619 28.4232 19.8752 28.4232 20.385C28.4232 20.8948 28.0099 21.3081 27.5001 21.3081H20.1155C19.6057 21.3081 19.1924 20.8948 19.1924 20.385ZM19.1924 24.0773C19.1924 23.5675 19.6057 23.1542 20.1155 23.1542H27.5001C28.0099 23.1542 28.4232 23.5675 28.4232 24.0773C28.4232 24.5871 28.0099 25.0004 27.5001 25.0004H20.1155C19.6057 25.0004 19.1924 24.5871 19.1924 24.0773ZM20.1155 26.8465C19.6057 26.8465 19.1924 27.2598 19.1924 27.7696C19.1924 28.2794 19.6057 28.6927 20.1155 28.6927H26.9597C27.2188 28.0711 27.5795 27.5025 28.0211 27.0075C27.8728 26.9059 27.6934 26.8465 27.5001 26.8465H20.1155Z"
          fill="#493877"
        />
      </svg>
    </SvgIcon>
  );
}
