import * as React from "react";
import Box from "@mui/material/Box";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const NewTestimonials: React.FC = () => {
  const first = require("../../assets/Home/CompressedWebP/testimonials1.webp");
  const second = require("../../assets/Home/CompressedWebP/testimonials2.webp");
  const third = require("../../assets/Home/CompressedWebP/testimonials3.webp");
  const fourth = require("../../assets/Home/CompressedWebP/testimonials4.webp");
  const fifth = require("../../assets/Home/CompressedWebP/testimonials5.webp");

  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  
  const leftArrow = require("../../assets/Home/CompressedWebP/circlebuttonleft.webp");
  const rightArrow = require("../../assets/Home/CompressedWebP/circlebuttonright.webp");

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: phone ? "fit-content" : "30rem",
        height: "fit-content",
        display: phone ? "none" : "block",
        margin: phone ? "1rem" : "0",
        padding: phone ? "1rem" : "2rem",
        backgroundColor: "white",
      }}
    >
      <h1 style={{ display: phone ? "block" : "none" }}>
        What people <br />
        are saying
      </h1>
      <h1 style={{
        display: phone ? "none" : "block",
        marginBottom: "2rem",
        fontSize: tablet ? "2rem" : "2.5rem",
        color: '#000000',
      }}>
        What people are saying
      </h1>
      <Box
        sx={{
          width: phone ? "100%" : tablet ? "100%" : "1000px",
          margin: "0 auto",
        }}
      >
        <Carousel
          autoPlay
          infiniteLoop
          showThumbs={false}
          showIndicators={false} // Hide indicators
          showStatus={false}
          renderArrowPrev={(clickHandler, hasPrev, label) => (
            <button
              onClick={clickHandler}
              aria-label={label}
              style={{
                position: "absolute",
                left: "10px",
                top: "30%",
                transform: "translateY(-50%)",
                zIndex: 1,
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
            >
              <img src={leftArrow} alt="Previous" />
            </button>
          )}
          renderArrowNext={(clickHandler, hasNext, label) => (
            <button
              onClick={clickHandler}
              aria-label={label}
              style={{
                position: "absolute",
                right: "10px",
                top: "30%",
                transform: "translateY(-50%)",
                zIndex: 1,
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
            >
              <img src={rightArrow} alt="Next" />
            </button>
          )}
        >
          {[
            {
              image: first,
              text: `“I like being able to filter caregivers by what you need. I need
                somebody with their own reliable transportation or I need
                somebody that speaks a different language.”`,
              author: "Mary",
            },
            {
              image: second,
              text: `“I just want to say a big thank you to Herewith. It’s been
                phenomenal for me. I even have a friend who wants to use it to
                take care of her mom.”`,
              author: "Pauline",
            },
            {
              image: third,
              text: `“Having that freedom of knowing my mom is in good hands with her
                caregiver is everything. Herewith gave that to me.”`,
              author: "Suzy",
            },
            {
              image: fourth,
              text: `“Through Herewith I found wonderful care for my hundred year old
                Italian mother... She’s always on time, upbeat, and as a CNA, a
                very capable and compassionate caregiver.”`,
              author: "Dolores",
            },
            {
              image: fifth,
              text: `“I appreciate that I can call Support when I have a question.
                It’s important for me to be able to reach people beyond the app
                when needed. Good customer service.”`,
              author: "George",
            },
          ].map(({ image, text, author }, index) => (
            <div key={index} style={{ minHeight: phone ? "8rem" : 0 }}>
              <img
                src={image}
                loading="lazy"
                alt={`Testimonial ${index + 1}`}
                style={{
                  margin: "1rem 0 2rem 0",
                  borderRadius: "1rem",
                  height: "104px",
                  width: "104px",
                  filter: "drop-shadow(0px 20px 19px #55524E3D)",
                }}
              />
              <div style={{
                fontSize: '20px',
                color: '#706D67',
                textAlign: 'center',
                marginBottom: '2rem',
              }}>
                {author}
              </div>
              <Divider sx={{ opacity: "50%", marginBottom: "1rem" }} />
              <p className="testimonial-text" 
                style={{
                fontSize: "22px",
                lineHeight: "1.5",
                marginBottom: phone ? "1rem" : "2.5rem",
              }}>
                {text}
              </p>
            </div>
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};

export default NewTestimonials;
