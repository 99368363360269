// @ts-nocheck
import { useState, useEffect } from "react";
import * as React from "react";

import { Link, useParams } from "react-router-dom";

import { format } from "date-fns";

import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Button,
  Divider,
} from "@mui/material";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import { createClient } from "@sanity/client";
import PhoneInTalkTwoToneIcon from "@mui/icons-material/PhoneInTalkTwoTone";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import IosShareOutlinedIcon from "@mui/icons-material/IosShare";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { PortableText, toPlainText } from "@portabletext/react";
import imageUrlBuilder from "@sanity/image-url";
import slugify from "slugify";
import { useLocation } from "react-router-dom";
import RelatedButton from "../../components/RelatedButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Padding, PaddingTwoTone, Style } from "@mui/icons-material";
import BackToTopButton from "../../components/BackToTopWhite";

const client = createClient({
  projectId: "kli49vb1",
  dataset: "production",
  useCdn: true,
  apiVersion: "2021-08-31",
});
const SampleImageComponent = ({ value, isInline }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "34rem",
        margin: "1rem auto",
        backgroundImage: `url(${imageUrlBuilder(client)
          .image(value)
          // .width(isInline ? 100 : 500)
          .fit("max")
          .auto("format")
          .url()})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    ></Box>
  );
};

const LinkableHeader = ({ children, value }) => {
  // `value` is the single Portable Text block of this header
  const theme = useTheme();
  const slug = slugify(toPlainText(value));
  return (
    <h2
      id={slug}
      style={{
        color: "black",
        lineHeight: 2,
        [theme.breakpoints.down("sm")]: {
          lineHeight: "1 !important",
          marginTop: "1.5rem",
        },
      }}
    >
      {children}
    </h2>
  );
};

const BodyParagraph = ({ children }) => {
  // `value` is the single Portable Text block of this header

  return (
    <p style={{ color: "black", lineHeight: 1.2, margin: "1rem 0" }}>
      {children}
    </p>
  );
};
const components = {
  marks: {
    internalLink: ({ value, children }) => {
      const { slug = {} } = value;
      const href = `/help-center/${slug.current}`;
      return (
        <a className="blogLink" href={href}>
          {children}
        </a>
      );
    },
    link: ({ value, children }) => {
      const { blank, href } = value;
      return (
        <a className="blogLink" href={href} target="_blank" rel="noopener">
          {children}
        </a>
      );
    },
  },
  types: {
    image: SampleImageComponent,

    // Any other custom types you have in your content
    // Examples: mapLocation, contactForm, code, featuredProjects, latestNews, etc.
  },
  block: {
    h2: LinkableHeader,

    normal: BodyParagraph,
  },
};

function samePageLinkNavigation(
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) {
  if (
    event.defaultPrevented ||
    event.button !== 0 || // ignore everything but left-click
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  ) {
    return false;
  }
  return true;
}

export default function SingleBlogPost() {
  const [singlePost, setSinglePost] = useState<any>();
  const { slug } = useParams();
  const [headers, setHeaders] = useState<any>();
  const [relatedPosts, setRelatedPosts] = useState<any>();
  const [value, setValue] = React.useState(0);
  const { hash } = useLocation();
  const isActive = (iHash) => hash === iHash;

  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  // const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  const backupimg = require("../../assets/genericarticleimage.jpg");

  const onPrint = (event: Event) => {
    event.preventDefault();
    window.print();
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // event.type can be equal to focus with selectionFollowsFocus.
    if (
      event.type !== "click" ||
      (event.type === "click" &&
        samePageLinkNavigation(
          event as React.MouseEvent<HTMLAnchorElement, MouseEvent>
        ))
    ) {
      setValue(newValue);
      console.log(value);
    }
  };

  const getHeaders = (arr: any) => {
    const filtered = arr.filter((i) => i.style === "h2");
    let strings = [];
    // @ts-ignore
    filtered.forEach((el) => strings.push(el.children[0].text));
    console.log("headers", strings);
    setHeaders(strings);
    return strings;
  };
  useEffect(() => {
    client
      .fetch(
        `*[slug.current == "${slug}"] {
      title,
      slug,
     
      body[]{
        ...,
        markDefs[]{
          ...,
          _type == "internalLink" => {
            "slug": @.reference->slug
          }
        }
      },
      publishedAt,
      description,
      "topics": topics[]-> {title},
      "related": related[]->{title,slug,description,author,mainImage {
        asset -> {
          _id,
          url
        },
        alt,
      },},
      mainImage {
        asset -> {
          _id,
          url
        },
        alt,
      },
      "name": author->name,
    }`
      )
      .then((data) => {
        console.log(data);

        setSinglePost(data[0]);
      })
      .catch(console.error);
  }, [slug]);

  useEffect(() => {
    if (singlePost?.body) {
      getHeaders(singlePost.body);
    }
    setRelatedPosts(singlePost?.related);
  }, [singlePost]);

  console.log(relatedPosts)

  return (
    <>
      <Box
        sx={{
          // height: "fit-content",
          width: "100%",
          height: "fit-content",
          backgroundColor: "white",

          maxWidth: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 ",
        }}
      >
        <Navbar id="scroll" color="black" />

        <Box
          sx={{
            width: "100%",
            height: phone ? "550px !important" : tablet ? "460px" : "460px",
            backgroundColor: "#FFE0EC",
            padding: "0",
            display: "flex",
            flexDirection: phone ? "column" : "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: phone
                ? "90%"
                : tablet
                  ? "70% !important"
                  : "75vw !important",
              minWidth: phone || tablet ? 0 : "45rem",
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              justifyContent: phone ? "flex-start" : "space-between",

              marginLeft: phone ? 0 : tablet ? "2rem" : "5rem",
              height: phone ? "55vh" : "70%",
              minHeight: "fit-content",
            }}
          >
            <>
              {singlePost && (
                <>
                  <Box>
                    <h1
                      style={{
                        width: "100%",
                        margin: phone ? "32px 0px 8px 0px" : tablet ? "0 auto 1.5rem auto" : "2rem auto",
                        fontSize: phone
                          ? "28px"
                          : tablet
                            ? "2rem"
                            : "2.5rem",
                      }}
                    >
                      {singlePost.title}
                    </h1>
                    <p
                      style={{
                        fontSize: phone || tablet ? "16px" : "1.25rem",
                        lineHeight: phone ? "24px" : 1.25,
                      }}
                    >
                      {" "}
                      {singlePost.description}
                    </p>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      width: phone ? "95%" : "80%",
                      marginTop: phone ? "2rem" : 0,
                      justifyContent: phone ? "space-between" : "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <p style={{ opacity: ".6" }}>Written by</p>
                      <p
                        style={{
                          fontSize: phone || tablet ? "1rem" : "1.25rem",
                          lineHeight: phone ? 1 : 2,
                          marginTop: phone ? "1rem" : 0,
                        }}
                      >
                        {" "}
                        By {singlePost.name}
                      </p>
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <p style={{ opacity: ".6" }}>Published</p>
                      <p
                        style={{
                          fontSize: phone || tablet ? "1rem" : "1.25rem",
                          lineHeight: phone ? 1 : 2,
                          marginTop: phone ? "1rem" : 0,
                        }}
                      >
                        {format(new Date(), "dd MMMM yyyy")}
                      </p>
                    </Box>
                  </Box>
                </>
              )}
            </>
          </Box>
          {singlePost?.mainImage && singlePost.mainImage?.asset && (
            <img
              src={singlePost.mainImage.asset.url}
              alt={singlePost.title}
              style={{
                width: phone ? "100vw" : "auto",
                height: "100%",
                backgroundColor: "transparent",
                margin: "0",
                objectFit: "cover",
                clipPath: phone ? "ellipse(90% 90% at bottom)" : tablet ? "circle(80% at 100% 100%)" : "circle(120% at 100% 130%)",
                display: phone ? "block" : tablet ? "none" : "block",
              }}
            />
          )}
        </Box>
        <Box>
          {singlePost && (
            <>
              <div>
                {/* Check for whether a preview image exists and display it if it does */}

                {/* Published At Date, formatted using `date-fns` in the format Day/Month/Year */}
                {/* {singlePost.publishedAt && (
                  <small>
                    By {singlePost.name} on{" "}
                    {format(new Date(singlePost.publishedAt), "dd MMMM yyyy")}
                  </small>
                )} */}
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flex: 1,
                    marginTop: phone ? "1rem" : "2rem",
                  }}
                >
                  {/* side panel */}
                  <Box
                    sx={{
                      width: "25%",
                      maxWidth: "25%",
                      minHeight: "100%",
                      flex: 1,
                      height: "fit-content",
                      display: phone || tablet ? "none" : "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        flexGrow: 1,
                        bgcolor: "background.paper",
                        display: "flex",
                        height: "fit-content",
                        width: "100%",
                        textAlign: "left",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        padding: "3rem 0 0 3rem",
                      }}
                    >
                      {/* <Box
                        sx={{
                          padding: "1.5rem 0",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}
                      >
                        <p>Topics</p>
                      </Box> */}

                      <Tabs
                        orientation="vertical"
                        variant="fullWidth"
                        value={value}
                        onChange={handleChange}
                        aria-label="nav tabs"
                        role="navigation"
                        // @ts-ignore
                        indicatorColor="transparent"
                        sx={{
                          width: "100%",
                          marginTop: "5rem",
                          // borderTop: 1,
                          // borderColor: "divider",
                        }}
                      >
                        {headers?.map((h, index) => {
                          console.log(h);
                          return (
                            <Link
                              smooth
                              to={`#${h.replace(/\s+/g, "-").split("?")[0]}`}
                              style={
                                isActive(
                                  `#${h.replace(/\s+/g, "-").split("?")[0]}`
                                )
                                  ? {
                                      color: "#0C3D4C",
                                      fontWeight: "bold",
                                    }
                                  : { color: "black" }
                              }
                              activestyle={{
                                fontWeight: "bold",
                                color: "#0C3D4C",
                              }}
                            >
                              <Tab
                                aria-current={h.selected && "page"}
                                disableRipple
                                label={h}
                                value={index}
                                sx={
                                  isActive(`#${h.replace(/\s+/g, "-")}`)
                                    ? {
                                        color: "#0C3D4C",
                                        fontWeight: "bold",
                                        textAlign: "left",
                                        alignItems: "flex-start",
                                        textTransform: "none",
                                        paddingLeft: 0,
                                        fontSize: "1.2rem",
                                        borderBottom: 1,
                                        borderColor: "divider",
                                      }
                                    : {
                                        textAlign: "left",
                                        alignItems: "flex-start",
                                        textTransform: "none",
                                        paddingLeft: 0,
                                        fontSize: "1.2rem",
                                        borderBottom: 1,
                                        borderColor: "divider",
                                      }
                                }
                              />
                            </Link>
                          );
                        })}
                      </Tabs>
                    </Box>{" "}
                  </Box>

                  {/* article */}
                  <Box
                    sx={{
                      width: phone || tablet ? "90%" : "60%",

                      maxWidth: "100vw",
                      margin: "2rem auto",
                      textAlign: phone ? "left" : "justify",

                      paddingBottom: "0px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent:
                          phone || tablet ? "flex-start" : "flex-end",
                        marginBottom: "1rem",
                      }}
                    >
                      <Stack direction="row" spacing={2}>
                        {/* <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            padding: ".5rem",
                            alignItems: "center",
                            width: "fit-content",
                          }}
                        >
                          <Avatar sx={{ backgroundColor: "#EBE9E6" }}>
                            <IosShareOutlinedIcon
                              sx={{
                                color: "black",
                              }}
                            />
                          </Avatar>
                          <p
                            style={{ fontSize: "1.2rem", marginLeft: ".5rem" }}
                          >
                            Share
                          </p>
                        </Box> */}
                        <Button
                          disableRipple
                          disableElevation
                          disableFocusRipple
                          onClick={(e: any) => onPrint(e)}
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            padding: ".5rem",
                            alignItems: "center",
                            width: "fit-content",
                            textTransform: "none",
                            backgroundColor: "transparent",
                            color: "black",
                            "&:hover": {
                              backgroundColor: "transparent",
                              color: "rgb(29, 153, 190)",
                            },
                          }}
                        >
                          {" "}
                          <Avatar
                            sx={{
                              backgroundColor: "#EBE9E6",
                              "&:hover": {
                                backgroundColor: "rgb(29, 153, 190)",
                              },
                            }}
                          >
                            <LocalPrintshopOutlinedIcon
                              sx={{ color: "black" }}
                            />
                          </Avatar>
                          <p
                            style={{ fontSize: "1.2rem", marginLeft: ".5rem" }}
                          >
                            Print
                          </p>
                        </Button>
                      </Stack>
                    </Box>
                    <Box
                      sx={{
                        "&:h2": {
                          lineHeight: 1.5,
                        },
                      }}
                    >
                      {/* Display your blog content - text, images, heading, links */}
                      {/* <BlockContent
                        blocks={singlePost.body}
                        projectId="9bnraqna"
                        dataset="production"
                      /> */}
                      <div style={{ textAlign: 'left'}}>
                      <PortableText
                        value={singlePost.body}
                        components={components}
                        projectId="9bnraqna"
                        dataset="production"
                      />
                    </div>
                    </Box>
                    {/* still need help */}
                    <Box
                      sx={{
                        borderTop: 1,
                        borderColor: "divider",
                        marginTop: "2rem",
                      }}
                    >
                      {" "}
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "transparent",
                        }}
                      >
                        <ListItem>
                          <ListItemText
                            primary={
                              <p style={{ fontSize: "1.2rem" }}>
                                Still need help? <br /> Call our Support team
                                for further assistance. <br /> Mon-Sat,
                                9AM-6:30PM PST
                              </p>
                            }
                            secondary=""
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar sx={{ backgroundColor: "#EBE9E6" }}>
                              <PhoneInTalkTwoToneIcon
                                sx={{ color: "#115C72" }}
                              />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            sx={{ color: "#115C72" }}
                            primary="Call Mon-Sat, 9AM-6:30PM PST"
                            secondary="415-506-9776"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar sx={{ backgroundColor: "#EBE9E6" }}>
                              <EmailTwoToneIcon sx={{ color: "#115C72" }} />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            sx={{ color: "#115C72" }}
                            primary="Send us an email"
                            secondary="support@herewith.com"
                          />
                        </ListItem>
                      </List>
                    </Box>

                    {/* related articles */}
                    <Divider/>

                    {relatedPosts && (
                      <Box sx={{ width: "100%" }}>
                        <h1 style={{fontSize: "28px", paddingTop: phone ? "32px" : "64px", display: phone ? "none":"block"}}>Related articles</h1>
                        <h2 style={{fontSize: "20px", paddingTop: phone ? "32px" : "64px", display: phone ? "block":"none"}}>Related articles</h2>
                        <Stack
                          direction={"column"}
                          spacing={4}
                          sx={{

                            width: "100%",
                          }}
                        >
                          {relatedPosts?.map((post) => {
                            var relAuthor = 'Herewith';
                            if (post.author._ref == "1fa0ff7d-5223-4bf7-9b63-146331f3d4c0"){
                              relAuthor = 'Jill Tomasetti';
                            }
                            return (
                              <>
                              <div style={{ marginTop: "24px" , marginBottom: "24px" , width: "100%", display: "flex", gap: "32px", alignItems: "center"}}>
                              <RelatedButton
                                title={post.title}
                                image={
                                  post?.mainImage?.asset?.url
                                    ? post.mainImage.asset.url
                                    : backupimg
                                
                                }
                                url={`/help-center/${post.slug.current}`}
                              />
                              <div style={{alignSelf:"center", textAlign:"left"}}>
                                <h2 style={{color: "#0C3D4C", fontSize: phone ? "16px" : "20px", lineHeight: phone ? "24px" : "28px"}}> {post.title}</h2>
                                <p style={{color: "#0C3D4C", display: phone ? "none" : "", lineHeight: "24px"}} > {post.description}</p>
                                <p style={{color: "#706D67", paddingTop:"8px"}}> {`by ${relAuthor}`}</p>
                              </div>
                              </div>
                              <Divider sx={{marginTop:"0px !important"}} />
                              </>
                              
                            );
                          })}
                        </Stack>
                      </Box>
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "80%",
                    margin: "2rem auto ",
                    textAlign: "center",
                    fontSize: "3rem",
                    marginTop: "0px"
                  }}
                >
                  {/* Link back to your main blog post page */}
                  <Link to="/help-center">Back to Help Center</Link>
                </Box>
              </div>
            </>
          )}
          {!singlePost && <h1>loading</h1>}
        </Box>
        <Footer />
      </Box>
      {/*<BackToTopButton />*/}
    </>
  );
}

// side nav sticky
